
.accordion {
  margin: 0 auto;
  padding: 0;
  list-style: none; 
}
.accordion.accordion-faq.accordion {
  border-top: none;
}

.accordion > li {
  border-bottom: 1px solid #CAC9C9;
  counter-increment: item;
  position: relative;
}

.accordion.accordion-faq > li {
  display: block
}

.accordion h3 {    font-family: "Yantramanav", sans-serif;margin: 0; transition:all 0.4s; color: #5B87DA; font-weight: 700; font-size: 19px;padding: 18px 0;}
.accordion li.active h3, .accordion li:hover h3 {color: #B67F00}

.accordion.accordion-faq.accordion h2 {padding: 26px 50px 26px 0; font-weight: 400;margin: 0; transition:all 0.4s; text-transform: none;font-family: "Maitree",Georgia,serif; letter-spacing: 0;color: #B78B1E; font-size: 20px; }
/*
.accordion li > a:hover {padding-left: 4px;}
*/

.accordion a {
  display: block;
  width: 100%;
  cursor: pointer;
}
.accordion.accordion-faq a {
  width: 100%;
}

.accordion-item {
  display: none;
  padding:0;
}

.accordion.accordion-faq .accordion-item {
  width: 100%;
  padding:0;
}



.accordion a:after {
  width: 16px;
  height: 9px;
  display: block;
  position: absolute;
      right: 0;
  content: " ";
  top: 25px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.091' height='9.667' viewBox='0 0 15.091 9.667'%3E %3Cpath id='Path_657' data-name='Path 657' d='M12.969%2C0%2C6.485%2C6.485%2C0%2C0' transform='translate(1.061 1.061)' fill='none' stroke='%23DBDADA' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");
  background-size: 16px 9px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.accordion a:hover:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.091' height='9.667' viewBox='0 0 15.091 9.667'%3E %3Cpath id='Path_657' data-name='Path 657' d='M12.969%2C0%2C6.485%2C6.485%2C0%2C0' transform='translate(1.061 1.061)' fill='none' stroke='%23B78B1E' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");

}

.accordion > li.active a:after {
  transform:rotate(180deg);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.091' height='9.667' viewBox='0 0 15.091 9.667'%3E %3Cpath id='Path_657' data-name='Path 657' d='M12.969%2C0%2C6.485%2C6.485%2C0%2C0' transform='translate(1.061 1.061)' fill='none' stroke='%23B78B1E' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");
}
.accordion > li.active a:hover:after {

}

.accordion > li .acc-container {
    padding: 0;
    margin: 60px 0;
    list-style: none;
}

.accordion > li ul {
    padding: 0;
    margin:10px 0;
    list-style: none;
}

.accordion > li ul li {
    display: block;
    position: relative;
    padding-left: 22px;
    padding-bottom:30px;
    line-height: 1.5;
}

.accordion ul li:before {
    content: '';
    font-weight: bold;
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #5B87DA;
    position: absolute;
    left: 0;
    top: 7px;
}


@media only screen and (max-width: 1023px) {


}

@media only screen and (max-width: 600px) {



}