
.fade-in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0); filter:alpha(opacity=0); opacity:0; -moz-opacity:0; -khtml-opacity:0; -webkit-opacity:0; }
.fade-in-view.in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100); filter:alpha(opacity=100); opacity:1; -moz-opacity:1; -khtml-opacity:1; -webkit-opacity:1}

.move-in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0); filter:alpha(opacity=0); opacity:0; -moz-opacity:0; -khtml-opacity:0; -webkit-opacity:0; -o-transform:translateY(70px); -ms-transform:translateY(70px); -moz-transform:translateY(70px); -webkit-transform:translateY(70px); transform:translateY(70px)}
.move-in-view.in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100); filter:alpha(opacity=100); opacity:1; -moz-opacity:1; -khtml-opacity:1; -webkit-opacity:1}

.move-in-view-left{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0); filter:alpha(opacity=0); opacity:0; -moz-opacity:0; -khtml-opacity:0; -webkit-opacity:0; -o-transform:translateX(70px); -ms-transform:translateX(70px); -moz-transform:translateX(70px); -webkit-transform:translateX(70px); transform:translateX(70px)}
.move-in-view-left.in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100); filter:alpha(opacity=100); opacity:1; -moz-opacity:1; -khtml-opacity:1; -webkit-opacity:1; -o-transform:translateX(0); -ms-transform:translateX(0); -moz-transform:translateX(0); -webkit-transform:translateX(0); transform:translateX(0)}

.move-in-view-right{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0); filter:alpha(opacity=0); opacity:0; -moz-opacity:0; -khtml-opacity:0; -webkit-opacity:0; -o-transform:translateX(-70px); -ms-transform:translateX(-70px); -moz-transform:translateX(-70px); -webkit-transform:translateX(-70px); transform:translateX(-70px)}
.move-in-view-right.in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100); filter:alpha(opacity=100); opacity:1; -moz-opacity:1; -khtml-opacity:1; -webkit-opacity:1; -o-transform:translateX(0); -ms-transform:translateX(0); -moz-transform:translateX(0); -webkit-transform:translateX(0); transform:translateX(0)}


 .touch .move-in-view-left, .touch .move-in-view-right{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0); filter:alpha(opacity=0); opacity:0; -moz-opacity:0; -khtml-opacity:0; -webkit-opacity:0; -o-transform:translateY(70px); -ms-transform:translateY(70px); -moz-transform:translateY(70px); -webkit-transform:translateY(70px); transform:translateY(70px)}
 .touch .move-in-view-left.in-view, .touch .move-in-view-right.in-view{-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100); filter:alpha(opacity=100); opacity:1; -moz-opacity:1; -khtml-opacity:1; -webkit-opacity:1}



.trans-med, .trans-med:hover{-webkit-transition:all 377ms ease-in-out; -moz-transition:all 377ms ease-in-out; -o-transition:all 377ms ease-in-out; transition:all 377ms ease-in-out; -ms-transition:all 377ms ease-in-out}
.trans-slow, .trans-slow:hover{-webkit-transition:all 477ms ease-in-out; -moz-transition:all 477ms ease-in-out; -o-transition:all 477ms ease-in-out; transition:all 477ms ease-in-out; -ms-transition:all 477ms ease-in-out}
.trans-slower, .trans-slower:hover{-webkit-transition:all 600ms ease-in-out; -moz-transition:all 600ms ease-in-out; -o-transition:all 600ms ease-in-out; transition:all 600ms ease-in-out; -ms-transition:all 600ms ease-in-out}
.trans-slowest, .trans-slowest:hover{-webkit-transition:all 870ms ease-in-out; -moz-transition:all 870ms ease-in-out; -o-transition:all 870ms ease-in-out; transition:all 870ms ease-in-out; -ms-transition:all 870ms ease-in-out}


.delay-1{transition-delay:100ms; -webkit-transition-delay:100ms; -moz-transition-delay:100ms; -o-transition-delay:100ms; -ms-transition-delay:100ms}
.delay-2{transition-delay:200ms; -webkit-transition-delay:200ms; -moz-transition-delay:200ms; -o-transition-delay:200ms; -ms-transition-delay:200ms}
.delay-3{transition-delay:300ms; -webkit-transition-delay:300ms; -moz-transition-delay:300ms; -o-transition-delay:300ms; -ms-transition-delay:300ms}
.delay-4{transition-delay:400ms; -webkit-transition-delay:400ms; -moz-transition-delay:400ms; -o-transition-delay:400ms; -ms-transition-delay:400ms}
.delay-5{transition-delay:500ms; -webkit-transition-delay:500ms; -moz-transition-delay:500ms; -o-transition-delay:500ms; -ms-transition-delay:500ms}
.delay-6{transition-delay:600ms; -webkit-transition-delay:600ms; -moz-transition-delay:600ms; -o-transition-delay:600ms; -ms-transition-delay:600ms}
