// Logo animation
.logo { @include transition(opacity .3s ease-in-out); }
.logo a { display: inline-block; height: 50px; width: 100%; }
.logo.white polygon, .logo.white path { fill: #fff; }
.logo-color .cls-3, .logo-color .napcap-n, .logo-color .napcap-a, .logo-color .napcap-p { fill: #00259a; }
.cls-1, .cls-2, .logo-color .cls-3 { fill-rule: evenodd; }
.logo-color .cls-2, .logo-color .napcap-c2, .logo-color .napcap-a2, .logo-color .napcap-p2 { fill: #b67f00; }
.logo-color .cls-1 { fill: #5b87da; }
.napcap-n { -webkit-transform: translateX(-30px); transform: translateX(-30px); @include transition(all 1s ease-in-out); }
.body-loaded .napcap-n { -webkit-transform: translateX(0px); transform: translateX(0px); }
.napcap-a { -webkit-transform: translateX(-60px); transform: translateX(-60px); @include transition(all 0.5s ease-in-out); }
.body-loaded .napcap-a { -webkit-transform: translateX(0px); transform: translateX(0px); }
.napcap-p { -webkit-transform: translateX(-90px); transform: translateX(-90px); @include transition(all 0.3s ease-in-out); }
.body-loaded .napcap-p { -webkit-transform: translateX(0px); transform: translateX(0px); }
.napcap-c2 { -webkit-transform: translateX(90px); transform: translateX(90px); @include transition(all 0.3s ease-in-out); }
.body-loaded .napcap-c2 { -webkit-transform: translateX(0px); transform: translateX(0px); }
.napcap-a2 { -webkit-transform: translateX(60px); transform: translateX(60px); @include transition(all 0.5s ease-in-out); }
.body-loaded .napcap-a2 { -webkit-transform: translateX(0px); transform: translateX(0px); }
.napcap-p2 { -webkit-transform: translateX(30px); transform: translateX(30px); @include transition(all 1s ease-in-out); }
.body-loaded .napcap-p2 { -webkit-transform: translateX(0px); transform: translateX(0px); }
.cls-1 { -webkit-transform: translateY(-40px); transform: translateY(-40px); @include transition(all 1.2s ease-in-out); }
.body-loaded .cls-1 { -webkit-transform: translateY(0px); transform: translateY(0px); }
.cls-2 { -webkit-transform: translateY(40px); transform: translateY(40px); @include transition(all 1.2s ease-in-out); }
.body-loaded .cls-2 { -webkit-transform: translateY(0px); transform: translateY(0px); }
.cls-3 { -webkit-transform: translateY(40px); transform: translateY(40px); @include transition(all 1.2s ease-in-out); }
.body-loaded .cls-3 { -webkit-transform: translateY(0px); transform: translateY(0px); }


/***** Secondary navigation ****/
.secondary-nav-holder { position: fixed; width: 100%; max-width: 1230px; margin: 0 auto; left: 0; right: 0; z-index: 1; }
.secondary-nav {position: relative;z-index: 6;bottom: 40px;width: calc(100vh - 90px);transform: rotate(-90deg);transform-origin: right top;text-align: right;    float: right;}
.secondary-nav ul {list-style: none;float: left;}
.secondary-nav li {float: right;padding-right: 30px;}
.secondary-nav li a {color: #5b87da;font-size: 18px;font-weight: 300;position: relative;}
.secondary-nav li a:hover {color: #00259a;}
.secondary-nav li.active a {color: #00259a;}
.nav-white .secondary-nav li a {color: #fff;}
.nav-white .secondary-nav li.active a {color: #00259a;}
.secondary-nav li a span {display:inline-block;width: 36px;height: 18px;position:absolute;top: -20px;float: right;@include transition(all .2s ease-in-out);float: right;left: 50%;margin-left: -18px;opacity: 0;    transform: rotate(90deg);}
.secondary-nav li a:hover span {}
.secondary-nav li a span:before, .secondary-nav li a span:after {display: block;position: absolute;right: auto;left: 0;width: 4px;height: 11px;background: #00259a;content: '';transition: all 0.1s ease-in-out;}
.secondary-nav li a span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.secondary-nav li a span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}
.secondary-nav li.active span {opacity: 1}







/* Main nav */
.nav-container { padding: 0 40px; margin: 0 auto; width: 100%; }
.nav-container .inner { max-width: 1640px; margin: 0 auto; width: 100%; }

nav {font-family: "Yantramanav", sans-serif;height: 90px; background-color: rgba(255, 255, 255, 0.99); position: -webkit-sticky; position: sticky; top: 0; z-index: 9999;/*margin-top: 20px; */}
nav .nav-container, nav .inner { display: -ms-flexbox; display: flex; -ms-flex-pack: justify; justify-content: space-between; height: 100% }

.logo { display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center }
.logo img, .logo a, .logo svg { display: block; width: 145px; height: 32px; overflow: hidden }
.navigation { list-style: none; padding: 0; margin: 0; -ms-flex: 1; flex: 1; display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; z-index: 5 }
.navigation>li { display: inline-block; position: relative; display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; height: 100% }
.navigation>li>a { display: inline-block; text-decoration: none; letter-spacing: 0.3px; color: #00259A; list-style: none; font-size: 18px; padding: 10px 40px 10px 0; display: -ms-flexbox; display: flex; height: 100%; -ms-flex-align: center; align-items: center }
.navigation>li:hover>a, .navigation>li.current-menu-item>a { color: #b67f00 }
.navigation>li.current-menu-parent>a { color: #b67f00; cursor: default }
.navigation>li>a svg { width: 16px; }
.navigation>li>a svg path { fill: #5B87DA; transition: all 0.3s ease-in-out; }
.navigation>li>a:hover svg path { fill: #b67f00; }


.navigation-social { list-style: none; padding: 0; margin: 0 30px 0 0; display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; z-index: 5 }
.navigation-social>li { display: inline-block; position: relative; display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; height: 100% }
.navigation-social>li>a { display: inline-block; text-decoration: none; letter-spacing: 0.3px; color: #00259A; text-transform: uppercase; list-style: none; font-size: 18px; padding: 10px 30px 10px 0; display: -ms-flexbox; display: flex; height: 100%; -ms-flex-align: center; align-items: center }
.navigation-social>li>a svg { width: 16px; }
.navigation-social>li>a svg path { fill: #5B87DA; transition: all 0.3s ease-in-out; }
.navigation-social>li>a:hover svg path { fill: #b67f00; }



@media only screen and (min-width:1024px) {
	.navigation>li.menu-item-has-children>a:after { content: ''; display: inline-block; width: 10px; height: 7px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.353' height='7.507' viewBox='0 0 11.353 7.507'%3E %3Cg id='icon-down' transform='translate(327.629 -503.227) rotate(90)'%3E %3Cpath id='Path_2206' data-name='Path 2206' d='M0%2C8.536%2C5.1%2C4.268%2C0%2C0' transform='translate(504.635 317.684)' fill='none' stroke='%2300259A' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E ");background-size: 10px 7px; background-repeat: no-repeat; margin-left: 10px; transition: all 0.3s; position: relative; top: -2px }
	.navigation>li.menu-item-has-children>a:hover:after {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.353' height='7.507' viewBox='0 0 11.353 7.507'%3E %3Cg id='icon-down' transform='translate(327.629 -503.227) rotate(90)'%3E %3Cpath id='Path_2206' data-name='Path 2206' d='M0%2C8.536%2C5.1%2C4.268%2C0%2C0' transform='translate(504.635 317.684)' fill='none' stroke='%23b67f00' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E "); }

	.navigation>li.menu-item-has-children:hover a:after { transform: rotate(180deg) }
	.navigation>li.menu-item-has-children>ul { padding: 30px 40px; margin: 0; list-style: none; position: absolute; top: 84px; width: 260px; left: -40px; background-color: #fff; border-top: 6px solid #b67f00; transition: all 0.1s ease-in-out 0.1s; transform: rotateX(-90deg); transform-origin: 0% 0%; -webkit-backface-visibility: hidden; backface-visibility: hidden; box-shadow: 0 4px 6px transparent }
	.navigation>li.menu-item-has-children:hover>ul { transition: all 0.3s ease-in-out 0ms; transform: rotateX(0deg); box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16) }


}


.right-nav { display: flex; text-decoration: none; list-style: none; align-items: center; margin: 0; padding: 0; }
.right-nav li a, .right-nav li .nav-item { display: inline-block; text-decoration: none; letter-spacing: 0.3px; color: #00259A; text-transform: uppercase; list-style: none; font-size: 14px; padding: 10px 0 10px 40px; display: -ms-flexbox; display: flex; height: 100%; -ms-flex-align: center; align-items: center; cursor: pointer; }

@media only screen and (max-width:1300px) {
	.nav-container {padding: 0 40px;}
	.logo img { max-width: 100px }
	.navigation { margin: 0 }
	.navigation>li>a { padding: 10px 15px; font-size: 17px }
	.navigation>li.menu-item-has-children>ul { padding: 20px 30px; top: 74px; left: -10px }
	.navigation>li.menu-item-has-children>ul li a { font-size: 17px; padding: 8px 0 }
}

@media only screen and (max-width:1023px) {

	.right-nav li a, .right-nav li .nav-item { padding: 20px 0 20px 20px; }
}

@media only screen and (max-width:600px) {
	.nav-container {padding: 0 30px;}
	//.logo img, .logo a, .logo svg { width: 90px; height: 32px; }
}

@media only screen and (max-width:360px) {
	//.logo img, .logo a, .logo svg { width: 70px; height: auto; }
	.right-nav li a, .right-nav li .nav-item { font-size: 12px; padding: 20px 0 20px 15px; }
	.right-nav {}
	.logo img, .logo a, .logo svg {width: 125px;height: 28px;}
	.navigation-social>li>a {padding: 10px 10px 10px 0;}
}