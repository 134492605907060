/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: transparent;
  border: none;
  color: #333;
  border: 1px solid #fff;
  transition:all 0.4s;
  transform:scale(1);
  transform-origin: center;
  opacity: 0.8;
}

.flickity-button:hover {
  background: transparent;
  cursor: pointer;
  opacity: 1;
  transform:scale(1.2);
  opacity: 1
}

.flickity-button:focus {
  outline: none;
  box-shadow:none;
}

.flickity-button:active {
  
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #fff;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-top: -45px;
  /* vertically center */

  padding: 20px;
  display: flex;
  align-items:center;
  justify-content:center;
  //mix-blend-mode:difference
}

.flickity-prev-next-button.previous { left: 40px;display: none; }
.flickity-prev-next-button.next { right: 40px; }



/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 25px;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}


@media only screen and (max-width:1300px) {
  .flickity-prev-next-button {
    width: 70px;
    height: 70px;
    padding: 15px;
    margin-top: -35px;
  }
  .flickity-prev-next-button .flickity-button-icon {
    width: 20px;
  }
  .flickity-prev-next-button.next { right: 30px; }
}

@media only screen and (max-width:600px) {
  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    padding: 13px;
  }
  .flickity-prev-next-button .flickity-button-icon {
    width: 15px;
  }
  .flickity-prev-next-button.next { right: 20px; }
}