section {padding: 60px 0;}
.bg-dark-blue {background-color: #00259A; color: #fff}
.mw-sm {max-width: 44vw}



.home-container {height: calc(100vh - 90px);display: flex; flex-direction:column; width: 100%;}
#skrollr-body {width: 100%;}

.home-top {flex:1;display: flex; position: relative;}

.home-bottom {}







.carousel {
  margin-bottom: 40px;
}

.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8C8;
  border-radius: 5px;
  counter-increment: carousel-cell;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(carousel-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel-nav .carousel-cell {
  height: 80px;
  width: 50%;
}

.carousel-nav .carousel-cell:before {
  font-size: 50px;
  line-height: 80px;
}

.carousel-nav .carousel-cell.is-nav-selected {
  background: #ED2;
}













.slideshow {
	position: relative;
	width: 100%;
	flex:1;
	display: flex
}

.slideshow > div {display: flex; position: relative; }

.slides-left {width: 70% }



.slides-right-1 {width: 15%}
.slides-right-2 {width: 15%}



.slide { 
  width: 100%; top: 0; bottom: 0; left: 0; right: 0; position: absolute; width: 100%; display: flex; flex-direction: column; justify-content: flex-end; opacity: 1;
 //transition: all .8s cubic-bezier(1, 0, 0.2, 1); z-index: 1 
}

//.slide-zindex {z-index:99}
.slide-closing { transition:all .9s;}


.slides-left .slide {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}
.slides-left .slide-closing {clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);}

//.slides-left .slide-closing {opacity: 0}
.slides-left .slide-image {transform:scale(1); transition:transform 0.9s}
.slides-left .slide-active .slide-image {transform:scale(1)}
.slides-left .slide-closing .slide-image {transform:scale(1.2)}
.slides-left .slide-bottom .slide-image {transform:scale(1.2)}

//.slides-right-1 .slide {clip-path:          polygon(0 0, 100% 0, 100% 100%  , 0 100%);}
//.slides-right-1 .slide-closing {clip-path:  polygon(0 0, 100% 0, 100% 0%    , 0 0%);}

.slides-right-1 .slide {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}
.slides-right-1 .slide-closing {clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);}
.slides-right-1 .slide-image {transform:scale(1); transition:transform 0.9s}
//.slides-right-1 .slide-closing .slide-image  {transform:scale(1.2)}
//.slides-right-1 .slide-bottom .slide-image {transform:scale(1.2)}

.slides-right-2  .slide-image {transform:scale(1); transition:transform 0.9s}
.slides-right-2 .slide {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}
.slides-right-2 .slide-closing {clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);}
//.slides-right-2 .slide-closing .slide-image  {transform:scale(1.2)}
//.slides-right-2 .slide-bottom .slide-image {transform:scale(1.2)}

//.slides-left .slide {}
//.slides-right-1 .slide {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}
//.slides-right-2 .slide {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);}
//.slides-left .slide-closing {opacity: 0}
//.slides-right-1 .slide-closing,
//.slides-right-2 .slide-closing {clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);}



.slide h1 {color: #fff;padding-bottom: 30px;font-size: clamp(32px, 4vw, 68px);}

.reveal { overflow: hidden; position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; align-items: flex-end; }
.reveal img, .reveal video { position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; -o-object-fit: cover; -o-object-position: 50% 50%; object-position: 50% 50%; height: 100%; z-index: 1 }
.slide-image { position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; align-items: flex-end; z-index: 2; }
.slide-image:before {content: ''; position: absolute;bottom: 0;left: 0; right: 0; height: 70%; background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.3)); z-index: 2}

.slide-info {position: relative;z-index: 2}
.slides-left .reveal {align-content:flex-end;justify-content: center}

.slide-info {max-width:820px;padding: 30px 40px;}

.slide-cta { background:transparent; color:#fff; opacity:1; position: relative;display: flex;align-items:center;font-family: "Yantramanav",sans-serif; font-size: 24px;font-size: clamp(16px, 2vw, 24px); font-weight: 900}
.slide-cta span {display:inline-block;width: 25px;height: 18px;position:relative;top: -2px; @include transition(all .2s ease-in-out)left: 0;}
.slide-cta span:before, .slide-cta span:after {display: block;position: absolute;right: auto;left: 0;width: 3px;height: 11px;background: #fff;content: '';transition: all 0.3s ease-in-out;}
.slide-cta span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.slide-cta span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}
.slides-right-1 .slide:hover .slide-cta span, 
.slides-right-2 .slide:hover .slide-cta span {cursor: pointer; left: 4px;}


.slide-bottom {z-index: -1}
.slide-closing {z-index: 2}
.slide-active {pointer-events:auto;z-index: 3}

.slides-right-1 .slide, 
.slides-right-2 .slide {cursor: pointer;}


@media screen and (max-width:768px){ 
  .reveal img, .reveal video {-o-object-position: 50% 100%;object-position: 50% 100%;}
  .slideshow, .slideshow>div {display: block;}
  .slides-left {height: 300px;width: 100%;min-height: 40vh}

  .slides-right-1, .slides-right-2 {height: 90px;width: 100%;}

}

