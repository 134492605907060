

/* REMOVE WHEN DONE */
.menu-item a img, img.menu-image-title-after, img.menu-image-title-before, img.menu-image-title-above, img.menu-image-title-below , .menu-image-hover-wrapper .menu-image-title-above {
    border: none;
    box-shadow: none;
    vertical-align: middle;
    display: inline;
    width: 100%;
}

.menu-image-title.menu-image-title-above, .menu-image-title.menu-image-title-below {
    text-align: left!important;
    display: block;
    padding: 15px 10px 0 0;
     position: relative;
 }

.menu-image-title:after {
    position:absolute;
    top: 18px;
    right: 0; 
    content:'';
    display:block;
    width: 15px;
    height: 10px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.553' height='5.918' viewBox='0 0 14.553 5.918'%3E %3Cpath id='icon-arrow-right' d='M.822%2C13.962v-.626q.98.486%2C1.366.733t1.177.873V2.808H4.2V14.941A9.562%2C9.562%2C0%2C0%2C1%2C6.74%2C13.32v.634a16.238%2C16.238%2C0%2C0%2C0-1.8%2C1.827%2C8.058%2C8.058%2C0%2C0%2C0-1%2C1.58H3.563a7.019%2C7.019%2C0%2C0%2C0-.926-1.506A16.347%2C16.347%2C0%2C0%2C0%2C.822%2C13.962Z' transform='translate(-2.808 6.74) rotate(-90)' fill='%23b78b1e'/%3E %3C/svg%3E ");
    background-repeat:no-repeat;
    background-position: left center;
    opacity:0;
    transition:opacity 0.4s, transform 0.4s;
    transform: translateX(-4px);
}



a:hover .menu-image-title:after {
    opacity:1;
    transform: translateY(0);
}


.menu-image-title-above.menu-image-not-hovered > img,
.menu-image-hovered.menu-image-title-above .menu-image-hover-wrapper, .menu-image-title-above .menu-image-hover-wrapper {
    display: block;
    padding-top: 10px;
    margin: 0 auto !important;
}
.menu-image-title-below.menu-image-not-hovered > img,
.menu-image-hovered.menu-image-title-below .menu-image-hover-wrapper, .menu-image-title-below .menu-image-hover-wrapper {
    display: block;
    padding-bottom: 0;
    margin: 0 auto !important;
    height: auto;
    width: 100%;
}
.menu-image-title-hide .menu-image-title, .menu-image-title-hide.menu-image-title {
    display: none;
}
/* Alignment of the Menu items. Divi, Twenty 17*/
#et-top-navigation .nav li.menu-item, .navigation-top .main-navigation li {
    display: inline-flex;
}

.megamenu li.menu-item-has-children > ul > li a {overflow: hidden;}
.megamenu li.menu-item-has-children > ul > li a .lazy-bg, .megamenu li.menu-item-has-children > ul > li img{ transition:all 0.4s;transform: scale(1); background-color: #eee;transform-origin: bottom;}
.megamenu li.menu-item-has-children > ul > li a:hover .lazy-bg, .megamenu li.menu-item-has-children > ul > li a:hover img{transform: scale(1.05);}

/* REMOVE WHEN DONE */



/************ NAVIGATION TRANSITION ************/
.nav-transition { position: fixed; left: 0; right: 0; z-index: 5 }

/************ NAVIGATION HOLDER ************/
.main-nav {position: fixed;top: 0;width: 100%;z-index: 5;background-color: rgba(255, 255, 255, 1); height: 100px;  /*webkit-backdrop-filter: saturate(180%) blur(8px); backdrop-filter: saturate(180%) blur(8px);*/height: 100px;transition: opacity 0.4s ease-in-out, visibility .4s ease-in-out, background-color 0.4s;}
.nav-top {height: 100px;}
.specials-strip-active.main-nav.nav-top {top: 50px; position: absolute;}
.specials-strip-active.main-nav.nav-show.nav-top {top: 50px;}

.main-nav .nav-inner {display: flex;justify-content: space-between;align-items: center;height: 100px;position: relative;margin: 0 auto;}
    /* Spacer for section below nav */
    .nav-spacer {padding-top: 90px;}

    /* Hide nav on down */
    .main-nav-2{position: fixed;top: 0;left: 0;right: 0;z-index: 99}
    .main-nav-2.nav-hide {opacity: 0; visibility: hidden; pointer-events: none;}
    .main-nav-2.nav-show { top: 0; opacity: 1; visibility: visible; }
    
    /* Reset ul's*/
    .main-nav ul{margin-bottom: 0; list-style: none;}
    .main-nav ul {margin-left: 0;padding-left: 0;}

/************ END NAVIGATION HOLDER ************/



/************ LOGO ************/
    .main-nav .logo {margin-left: 30px;}
    .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {display: block; width: 222px; height: 50px; position: relative;z-index: 2 }
/************ END LOGO ************/



/************ MEGA MENU ************/
/* Main menu */
    .megamenu { flex: 1; margin-right: 0px;}
    .megamenu > ul {display: flex;/*justify-content: flex-end;*/position: relative;}

/* Top level text */
    .megamenu > ul > li { padding: 0 16px; text-decoration: none; transition: all .3s ease; height: 90px; display: flex; align-items: center;}
    .megamenu li.menu-item-has-children {/*padding-right: 24px;*/}
    .megamenu > ul > li > a { color: #00259A; position: relative; font-weight: 900; font-size: 18px; text-decoration: none;padding: 10px 0; white-space: nowrap;}
    .megamenu > ul > li.current-menu-item > a, .megamenu > ul > li.current-menu-parent > a{ color: #B78B1E;}
    .megamenu > ul > li > a:hover, .megamenu > ul > li.menu-hovered > a { color: #B78B1E }

/* Top level Hover line */
    .megamenu > ul > li > a:after { /*content: '';*/ display: block; position: absolute; bottom: -18px; left: 0; right: 0; height: 2px; background-color: #B78B1E; width: 0; transition: all 0.4s; margin: 0 auto; }
    .megamenu .menu-item-has-children.menu-hovered > a:after { width: 100%; }

/* Top level  arrow */
    .megamenu li.menu-item-has-children {padding-right: 24px;}
    .megamenu > ul > li.menu-item-has-children > a:before { content: '';display: block; position: absolute; top: 23px; right: -16px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.091' height='9.667' viewBox='0 0 15.091 9.667'%3E %3Cpath id='Path_657' data-name='Path 657' d='M12.969%2C0%2C6.485%2C6.485%2C0%2C0' transform='translate(1.061 1.061)' fill='none' stroke='%2300259A' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");
    width: 10px;height: 6px;background-size: cover; background-size:10px 6px; background-repeat: no-repeat; transition: all 0.5s}
    .megamenu li.menu-item-has-children.menu-hovered > a:before {transform: rotate(180deg);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.091' height='9.667' viewBox='0 0 15.091 9.667'%3E %3Cpath id='Path_657' data-name='Path 657' d='M12.969%2C0%2C6.485%2C6.485%2C0%2C0' transform='translate(1.061 1.061)' fill='none' stroke='%23B78B1E' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");

    }

 /* Mega menu opener container */ 
    .megamenu li.menu-item-has-children > ul {max-height: calc(100vh - 84px);overflow-y: auto;-webkit-overflow-scrolling: touch;left: -50px;width: calc(100% + 124px);max-width: 1144px;position: absolute;top: 48px;border-top: 42px solid transparent;z-index: 999;visibility: hidden;opacity: 0;z-index: 0;/* transform: translateY(-6px); */transition: opacity 0.4s, visibility 0s 0.4s, transform 0.3s;pointer-events: none;display: flex;flex-wrap: wrap;padding: 50px 50px 30px 50px;}
    .touch .megamenu li.menu-item-has-children > ul {top: 90px;border-top: none;}

/* Mega menu opener container open */
    .megamenu li.menu-item-has-children.menu-hovered > ul {pointer-events: auto;visibility: visible;opacity: 1;z-index: 1;transform: translateY(0);transition: opacity 0.3s, transform 0.3s;/* background: white; */}

    .megamenu li.menu-item-has-children > ul:before {content: '';top: 0;bottom: 0;left: 0;right: 0;position: absolute;z-index: -1;background-color: #fff; opacity: 0;transition: opacity 0.5s}
    .megamenu li.menu-item-has-children.menu-hovered > ul:before {opacity: 1;transition: opacity 0s}


 /* Mega menu columns */ 
    .megamenu .menu-item-has-children > ul > li {height: auto;/*width: calc(18.75% - 20px);*/margin: 0 10px 30px 10px; font-family: "Merriweather",serif; flex:1; max-width: 170px;}


    .megamenu .menu-item-has-children > ul > li:first-child {color:#B67F00; max-width: 100%;}
    @media only screen and (min-width: 1024px) {
        .megamenu .menu-item-has-children > ul > li:first-child {width: calc(25% - 20px);padding-right: 30px;max-width: 265px;}
    }


    .megamenu .menu-item-has-children.menu-three-col > ul > li {height: auto;width: calc(33.33333% - 20px);margin: 0 10px 30px 10px;}
    .megamenu .menu-item-has-children > ul > li:first-child { }

    /* Menu all */
    .megamenu .menu-item-has-children > ul > li.menu-all { display: flex; align-items: center; margin-bottom: 91px;}
    .megamenu .menu-item-has-children > ul > li.menu-all a{text-align: center;padding: 0 20px;font-size: 12px;letter-spacing: 1px;font-weight:600;width: 100%;line-height: 1.2;border: 1px solid rgba(26, 44, 66, 0.76);/* background-color: #f7f8f9; */display: flex;flex-direction: column;height: 100%;justify-content: center;margin-bottom: 0;padding: 20px 0;}
    .megamenu .menu-item-has-children > ul > li.menu-all a:hover {border-color:#B78B1E; background-color:#f7f8f9}


    .megamenu .menu-item-has-children > ul > li.menu-all a:before { content: ''; display: block; margin-bottom: 15px; width: 100%; text-align: center; height: 10px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.553' height='5.918' viewBox='0 0 14.553 5.918'%3E %3Cpath id='icon-arrow-right' d='M.822%2C13.962v-.626q.98.486%2C1.366.733t1.177.873V2.808H4.2V14.941A9.562%2C9.562%2C0%2C0%2C1%2C6.74%2C13.32v.634a16.238%2C16.238%2C0%2C0%2C0-1.8%2C1.827%2C8.058%2C8.058%2C0%2C0%2C0-1%2C1.58H3.563a7.019%2C7.019%2C0%2C0%2C0-.926-1.506A16.347%2C16.347%2C0%2C0%2C0%2C.822%2C13.962Z' transform='translate(-2.808 6.74) rotate(-90)' fill='%23b78b1e'/%3E %3C/svg%3E ");background-repeat:no-repeat;background-position: center center;}


    /* Animations whoohoo */
        .megamenu .menu-item-has-children > ul > li {opacity: 0;transition:opacity 0.4s 0s, transform 0.4s;transition-delay: 0s;transform: translate3d(0, -30px, 0);}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li {opacity: 1;transition:opacity 0.8s ease, transform 0.4s; transition-delay: 0s; transform: translate3d(0, 0, 0)}

        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(2n) {transition-delay: 0.05s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(3n) {transition-delay: 0.1s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(4n) {transition-delay: 0.15s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(5n) {transition-delay: 0.15s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(6n) {transition-delay: 0.2s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(7n) {transition-delay: 0.25s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(8n) {transition-delay: 0.3s}

        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(9n) {transition-delay: 0.3s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(10n) {transition-delay: 0.35s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(11n) {transition-delay: 0.4s}
        .megamenu li.menu-item-has-children.menu-hovered > ul > li:nth-child(12n) {transition-delay: 0.45s}


        .megamenu li.menu-item-has-children > ul > li a {text-decoration:none;display:block;color: #00259A;font-size: 15px;font-weight: 400;line-height: 1.4;height: 100%;}
        .megamenu li.menu-item-has-children > ul > li a:hover, .megamenu li.menu-item-has-children > ul > li.current-menu-item a {color: #B78B1E;}




        
 /* Menu Content box */ 
    .megamenu .menu-item-has-children > ul > li .h3 { }
    .megamenu .menu-item-has-children > ul > li p { line-height: 24px; color: #67777E; padding-right: 60px; font-size: 16px;margin: 10px 0 30px 0; }

/* Big headings */


/* Sub headings (e.preventdefault) */
    .megamenu .menu-item-has-children > ul > li > ul > li > a { font-family: 'AvenirLTPro-Heavy', sans-serif; font-size: 22px;line-height: 1.3; color: #273D46; text-decoration: none; display: block; padding: 15px 0; transition: all .3s ease; cursor: default; }

/* Hide sub heading*/
    .holder-menu-sub > a {display: none!important;}

/* Links in menu */
    .megamenu .menu-item-has-children > ul > li > ul > li > ul > li {margin: 14px 0;}

    .megamenu .menu-item-has-children > ul > li > ul > li > ul > li > a { font-family: 'AvenirLTPro-Medium', sans-serif; font-size: 17px; color: #2363C7; text-decoration: none;  padding: 1px 0;  transition: all .3s ease;border-bottom: 1px solid rgba(76, 143, 246, 0.3);    padding: 3px 0;line-height: 2;}
    .megamenu .menu-item-has-children > ul > li > ul > li > ul > li.current-menu-item > a, .megamenu .menu-item-has-children > ul > li > ul > li > ul > li.current-menu-item > a {color: #B78B1E; border-bottom: 2px solid #B78B1E;  }
    .megamenu .menu-item-has-children > ul > li > ul > li > ul > li > a:hover { color: #B78B1E; border-bottom: 2px solid #B78B1E; }

    .holder-menu-sub > ul > li > a {font-size: 20px!important; }


/* Mega menu open opacity layer */
    .overlay {position: fixed;top: 0;right: 0;left: 0;bottom: 0;visibility: hidden;background-color: rgba(0,0,0,0.8);webkit-backdrop-filter: saturate(180%) blur(6px); backdrop-filter: saturate(180%) blur(6px);transition: opacity 0.2s, visibility 0s 0.2s;z-index: 5;opacity: 0;}
    .menu-open .nav-overlay { visibility: visible; pointer-events: auto; opacity: 1; transition: opacity 0.2s; }
/************ END MEGA MENU ************/



/************ RIGHT MENU ************/
/* All right menu styles */
    .right-menu {display: flex;margin-right: 30px;}
    .right-menu > ul{display: flex;}
    .right-menu > ul > li{display: flex; align-items: center;margin-left: 14px;}
    .right-menu > ul > li > a {letter-spacing: 1px;font-weight: 600;font-size: 13px;text-transform: uppercase;height: 100%;display: flex;align-items: center;text-decoration: none;white-space: nowrap;text-align: center;}
    .right-menu > ul > li > a:hover {color:#B78B1E}



/* cta */
    ul li.nav-cta a {display: flex;background-color: #B78B1E!important;border:1px solid #B78B1E; padding: 10px 15px;color: #fff}
    ul li.nav-cta a span {position: relative;top: -1px;}
    ul li.nav-cta a:hover {background-color: #524636!important;color: #fff;border:1px solid #524636;}
    ul li.nav-cta a:focus {}

    ul li.nav-cta.nav-cta-ghost a {color:#1A2C42; border:1px solid #1A2C42; background-color: transparent!important;}
    ul li.nav-cta.nav-cta-ghost a:hover {color:#B78B1E; border:1px solid #B78B1E; }

    .nav-number { font-size: 13px; font-weight: 600;padding-left: 10px;}
    .nav-number a {color: red}
    .nav-number svg {width: 15px;margin-right: 0;position: relative;bottom: 0;}
    .nav-number svg path {}

/************ END RIGHT MENU ************/


/* Transparent menu*/
.nav-transparent.nav-top {background-color: transparent;}

.nav-transparent.nav-top .logo svg path {fill:#fff; transition:all 0.4s}
.menu-open .main-nav .logo svg path, .main-nav.menu-opened .logo svg path  {fill:#1A2C42!important}
.nav-transparent-gradient:after { content: ''; position: absolute; left: 0; right: 0; top: 0; height: 30%; z-index: 1; background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }


@media only screen and (min-width: 1024px) {
    .nav-transparent.nav-top .megamenu > ul > li > a {color: #fff; opacity: 0.9;}
    .nav-transparent.nav-top .megamenu > ul > li > a:hover {opacity: 1;}

    .menu-open .nav-transparent.nav-top .megamenu > ul > li > a {color: #1A2C42;}

    .nav-transparent.nav-top ul li.nav-cta.nav-cta-ghost a { color: #fff; border: 1px solid #fff; background-color: transparent !important; opacity: 0.9; }
    .nav-transparent.nav-top ul li.nav-cta.nav-cta-ghost a:hover {color:#B78B1E; border:1px solid #fff;background-color: #fff!important; opacity: 1; }

    .menu-open .main-nav { background: rgba(255, 255, 255, 1); }
    .menu-open .main-nav ul li.nav-cta.nav-cta-ghost a { color: #1A2C42; border: 1px solid #1A2C42; background-color: transparent !important; }

}
/* END Transparent menu*/

/************ RESPONSIVE ************/
    @media only screen and (min-width: 1300px) {
        //.nav-spacer-home {min-height: calc(100vh - 84px);}
        .nav-spacer-home {min-height:100vh;}
         .megamenu .menu-item-has-children > ul > li:first-child {}
    }

     @media only screen and (min-height: 1000px) and (max-width: 1400px)  {
        .nav-spacer {min-height: auto;}
    }
    @media only screen and (max-width: 1220px) {
        .main-nav {height: 90px;}
        .main-nav.nav-top {padding-top: 0;height: 90px;}

        .main-nav .nav-inner {height: 90px;}
        .nav-spacer {padding-top: 90px;}
        .main-nav .logo {margin-left: 30px;}

        .megamenu li.menu-item-has-children > ul {    width: calc(100% + 235px);}
        .touch .megamenu li.menu-item-has-children > ul {top: 87px;border-top: none;padding: 0px 30px 0 30px;}
        .megamenu .menu-item-has-children > ul > li {}
        .megamenu .menu-item-has-children > ul > li p {padding-right: 0;}



        .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {width: 195px; height: 43px;}
            
        .megamenu > ul > li {padding: 0 11px;}
        .megamenu > ul > li > a {font-size: 17px;}
        .megamenu > ul > li > a:after {bottom: -14px;}

        .megamenu li.menu-item-has-children {/*padding-right: 22px;*/}
        .megamenu > ul > li.menu-item-has-children > a:before {top: 19px;}

        .right-menu > ul > li > a {font-size: 12px;}
        .right-menu ul li.nav-cta a {padding: 10px 15px;}


        .search-opener {padding-right: 10px;padding-left: 10px;}

        .nav-number { padding-left: 0px;}

    }


    .menu-opener {display: none;}

    @media only screen and (min-width: 1024px) {
        .show-nav-mobile {display: none!important}
    }

    @media only screen and (max-width: 1023px) {
        .show-nav-desktop {display: none!important}
        nav {     height: auto; }
        .main-nav {height: 66px;}
        .nav-container {padding: 0;}

        .navigation-social {
            list-style: none;
            padding: 0;
            margin: 0 0 0 30px;
            display: flex;
            align-items: center;
            z-index: 5;
        }

        .navigation-social>li>a {padding: 10px 15px 10px 0;}


        .main-nav.nav-top {padding-top: 0;height: 66px;}
        .main-nav.nav-top .megamenu {margin-top: 66px;}
        .specials-strip-active.main-nav.nav-top .megamenu {margin-top: 116px;}

        .main-nav.menu-opened {background-color: #fff;}

        .main-nav .nav-inner {height: 66px;}
        .nav-spacer {padding-top: 66px;}
        .main-nav .logo {margin-left: 30px;flex: 1;}

        .megamenu {margin-top: 66px;margin-left: 0; position: fixed; top: 0; right: 0;width: 50%;min-width: 366px; bottom: 0; pointer-events: none;background-color: #fff;transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s;  visibility: hidden;opacity: 0;    transform: translateX(100%); }
        .menu-opened .megamenu  { visibility: visible; pointer-events: auto; opacity: 1;transform: translateX(0); transition: opacity 0.3s, transform 0.3s;overflow-y: auto;-webkit-overflow-scrolling: touch;}
        
        .megamenu > ul {flex-direction: column;/* border-top: 2px solid #EDEFF4; */}
        .megamenu li.menu-item-has-children {height: initial;padding-right: 0;}
        .megamenu > ul > li { padding: 0; height: initial; align-items: flex-start; flex-direction: column;background-color: #F7F8F9; }
        .megamenu > ul > li > a {font-size: 20px;display: block;width: 100%;padding: 20px 0 20px 40px;border-top: 2px solid #EDEFF4;background-color: #fff;}
        .megamenu > ul > li:last-child > a {/* border-top: none; */}


        ul li.nav-cta a {border:none;    padding: 20px 40px!important;    margin: 0;}
        ul li.nav-cta.nav-cta-ghost a {color: #fff;border: none; background-color: #1A2C42!important;}

        .megamenu li.menu-item-has-children {padding-right: 0;}


        .megamenu > ul > li > a:after { bottom: -2px;margin: 0; }
        .megamenu li.menu-item-has-children > ul, .touch .megamenu li.menu-item-has-children > ul { max-height: initial;width: 100%; position: static; overflow-y: initial; display: block; padding: 0; max-height: 0; overflow: hidden; padding: 0; transition: max-height 0.8s, border 0.3s 0.3s,opacity 0.3s, padding 0.3s 0.3s; }
        .megamenu li.menu-item-has-children.menu-hovered > ul {padding: 40px 40px  40px 40px;max-height: 1000px;width: 100%;border-top: 2px solid #B78B1E;transition: max-height 0.8s, border 0.3s 0.3s, opacity 0.3s, padding 0s 0s;background-color: #edeff400;}
        .megamenu li.menu-item-has-children > ul:before { display: none; }
        .megamenu .menu-item-has-children > ul > li {max-width: 100%;height: auto;padding-right: 0;padding-left: 0;width: 100%;margin: 0 0 20px;}
        .megamenu .menu-item-has-children > ul > li:last-child {margin-bottom:0}

        .megamenu .menu-item-has-children > ul > li.menu-all a {background-color:#fff; margin-top:10px}
        .megamenu .menu-item-has-children > ul > li.menu-all br {display:none}


        .megamenu li.menu-item-has-children > ul > li a {margin-bottom: 0;font-size: 17px;}
        .megamenu li.menu-item-has-children > ul > li a img {display:none}

        .menu-image-title:before, .menu-image-title:after {display:none}
        .menu-image-title.menu-image-title-above, .menu-image-title.menu-image-title-below {padding:0;}


        .megamenu > ul > li.menu-item-has-children > a:before {top: 32px;

    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.091' height='9.667' viewBox='0 0 15.091 9.667'%3E %3Cpath id='Path_657' data-name='Path 657' d='M12.969%2C0%2C6.485%2C6.485%2C0%2C0' transform='translate(1.061 1.061)' fill='none' stroke='%2300259A' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");
background-size: 18px 11px;

            width: 18px; height: 11px; background-size: cover;right: 28px;}
        .megamenu .menu-item-has-children > ul > li > ul > li > a { font-size: 19px; padding: 30px 0 0px 0; }


        .megamenu .menu-item-has-children > ul > li > ul > li > ul > li:last-child,
        .megamenu .menu-item-has-children > ul > li > ul > li > ul > li:last-child p{margin-bottom: 0;}
        .megamenu .menu-item-has-children > ul > li > ul > li > ul > li > a { font-size: 17px; border-bottom: 2px solid rgba(100, 128, 139, 0.3);}
        .holder-menu-sub > ul > li > a {font-size: 17px !important;}
        .megamenu .menu-item-has-children > ul > li p {padding-right: 0;font-size: 16px;margin: 10px 0 20px 0;}
        .megamenu .menu-item-has-children > ul > li > ul > li > ul > li > a br {display: none;}
        .nav-overlay { display: none; }


        .megamenu .menu-item-has-children > ul > li:first-child {display: none;}

        /* Mobile search */
        .nav-search {margin: 30px;}
        .nav-search form {width: 100%}
        .search-flex-mobile {display: flex;}
        .search-flex-mobile input[type="search"]{flex:1; height: 64px;line-height: 64px; font-size: 19px;padding: 20px; background-color: #F7F8F9}
        .search-flex-mobile input[type="submit"] {margin-top: 0; border: 0;padding: 10px 30px;font-size: 14px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.221' height='18.221' viewBox='0 0 18.221 18.221'%3E %3Cg id='icon-search' transform='translate(-0.807 -0.807)'%3E %3Ccircle id='Ellipse_123' data-name='Ellipse 123' cx='6.5' cy='6.5' r='6.5' transform='translate(1.807 1.807)' stroke-width='2' stroke='%23F07100' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' fill='none'/%3E %3Cpath id='Path_3400' data-name='Path 3400' d='M20.684%2C20.684%2C16%2C16' transform='translate(-3.071 -3.071)' fill='none' stroke='%23F07100' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E ");background-color: transparent;background-repeat: no-repeat;background-position:center;width: 40px;}

        .search-flex-mobile input[type="search"]::-webkit-input-placeholder {text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}
        .search-flex-mobile input[type="search"]:-ms-input-placeholder {text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}
        .search-flex-mobile input[type="search"]::placeholder { text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}


        /* Burger */
        .menu-opener { height: 66px; width: 66px;background-color: #B78B1E; cursor: pointer; overflow: hidden; display: flex; flex-direction: column; align-items: center; justify-content: center; }
        .menu-opened .menu-opener {background-color: #edeff4}

        .nav-transparent.nav-top .menu-opener {background-color: transparent;margin-right: 20px}

        .menu-opener span { position: relative; display: block; width: 26px; height: 2px; background-color: #ffffff; transition: top .2s 0.2s, transform .2s, background-color 0.3s ease; transform-origin: center center; }
        .menu-opened .menu-opener span { transition: top .2s, transform .2s 0.2s, background-color 0.3s ease; transform-origin: center center; background-color: #B78B1E }
        
        .menu-opener span:first-child { top: -8px; transform: rotate(0deg); }
        .menu-opener span:nth-child(2) { transition: width 0.2s, opacity 0.2s, background-color 0.2s ease; }
        .menu-opener span:last-child { top: 8px; }

        .menu-opened .menu-opener span:first-child { top: 2px; transform: rotate(45deg); }
        .menu-opened .menu-opener span:nth-child(2) { opacity: 0; width: 0; }
        .menu-opened .menu-opener span:last-child { top: -2px; transform: rotate(-45deg); }

        .body-menu-opened .nav-overlay-mobile { visibility: visible; pointer-events: auto; opacity: 1; transition: opacity 0.5s; }
        .body-menu-opened {overflow:hidden!important;}
    }

    @media only screen and (max-width: 500px) {
        .main-nav .logo {margin-left: 20px;}
        .megamenu {width: 100%; min-width: auto;}
    }
    @media only screen and (max-width: 380px) {
        .logo img, .logo a, .logo svg {width: 120px;height: 26px;}
        
    }
    @media only screen and (max-width: 340px) {
        
    }

    @media only screen and (min-width: 1300px) {
        .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {
            width: 250px;
            height: 56px;
        }
        .main-nav .logo {
            margin-left: 50px;
        }
        .right-menu {
            margin-right: 50px;
        }
    }

/************ END RESPONSIVE ************/