/* Content effect*/
.m-scene .scene-main.page-content{ animation-duration: 0.6s; transition-timing-function: ease-in; animation-fill-mode: both; }
.m-scene .scene-main--fadein.page-content{ animation-name: fade; }
.m-scene.is-exiting .scene-main.page-content{ animation-direction: alternate-reverse; }
.m-scene .scene-nav.page-content{ animation-duration: 0.6s; transition-timing-function: ease-in; animation-fill-mode: both; animation-name: width; }
.m-scene.is-exiting .scene-nav.page-content{ animation-direction: alternate-reverse; }

@keyframes fade { 
	0% { opacity: 0;}
  100% { opacity: 1;} 
}
@-webkit-keyframes fade { 
	0% { opacity: 0;}
  100% { opacity: 1;} 
}


