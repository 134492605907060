body {
	font-family: $font-family;
	line-height: 1.8;
	font-size: 15px;
	color: $body-text-color;
	background-color: $body-background-color;
	font-weight: 300;
}


// Links
a {
	color: $link-color;
	@include transition(all .2s ease-in-out);
}
a:hover {
	color: $link-hover-color;
}

.color-gold {color: #b67f00!important}

// Headings
h1.title, h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font-family;
	font-weight: bold;
	text-rendering: optimizeLegibility;
	color: $heading-text-color;
	margin-bottom: $heading-margin-bottom;
}
h1.title {
    font-size: 346px;
    line-height: 276px;
    padding: 0 0 40px 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 900;
    font-family: $font-family-2; 

}
h1,
.h1 {
    font-size: 61px;
    line-height: 1;
    padding: 0 0 80px 0;
    margin: 0;
    color:#5b87da;
    font-weight: 900;
    font-family: $font-family-2; 
}
h2, .h2 {
    font-size: 21px;
    line-height: 1.3;
    color: #00259a;
    font-weight: 600;
    font-family: $font-family; 
    padding-bottom: 30px;
}
h3,
.h3 {
    font-size: 18px;
    line-height: 1.4;
    color: #00259a;
    font-weight: 600;
    font-family: $font-family; 
    padding-bottom: 50px;
}
h4,
.h4 {
	font-size: $h4-font-size;
	line-height: $h4-line-height;
	color: $h4-color;
	font-weight: 900;
	font-family: $font-family-2; 
	margin-bottom: 40px
}
h5,
.h5 {
	font-size: $h5-font-size;
	line-height: $h5-line-height;
	color: $h5-color;
	font-weight:700;
	margin-bottom: 10px;
	font-family: $font-family-2; 
}
h6,
.h6 {
	font-size: $h6-font-size;
	line-height: $h6-line-height;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: $h6-color;
}
@media only screen and (min-width: 1600px) { 
h1.title {
    font-size: 446px;
    line-height: 376px;
    padding: 0 0 40px 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.4);
    font-weight: 900;
    font-family: $font-family-2; 
    
	}
}

@media only screen and (max-width: 1300px) { 

}

@media only screen and (max-width: 768px) { 

}


@media only screen and (max-width: 580px) { 

}



p,
ul,
ol,
dl,
blockquote,
hr,
pre,
table,
form,
figure {
	+ h2,
	+ h3,
	+ h4,
	+ h5,
	+ h6 {
		margin-top: $base-unit * 8;
	}
}

// Subheadings
.subheading {
	margin-top: -($heading-margin-bottom - $base-unit);
	margin-bottom: ($base-unit*2);
	font-weight: 300;
}


// Lists
ul, ol,
ul ul,
ol ol,
ul ol,
ol ul {
	margin: 0 0 0 $base-line;
}
ol ol li {
	list-style-type: lower-alpha;
}
ol ol ol li {
	list-style-type: lower-roman;
}
nav ul, nav ol {
	margin: 0;
	list-style: none;
}
dl dt {
	font-weight: 700;
}
dd {
	margin-left: $base-line;
}

// Block
p, blockquote, hr, pre, ol, ul, dl, table, fieldset, figure, address, form {
	margin-bottom: $paragraph-margin-bottom;
}
hr {
	border-bottom: 1px solid #5B87DA;

}
blockquote {
	padding:0;
	margin:0;
    color: #5B87DA;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.7;
}

@media only screen and (max-width: 1023px) { 
	blockquote {
	    font-size: 19px;
	}
}





blockquote.blockquote-white{
	padding: 30px 50px 26px 50px;
    margin-top: 120px;
    background: #fff;
}

// Preformatted
pre, code, samp, var, kbd {
	font-family: $monospace-font-family;
	font-size: $font-size-small;
}
pre {
	color: rgba(0, 0, 0, .85);
	background: $pre-background-color;
	padding: 20px;
	border-radius: 4px;
	line-height: $base-line-small;
	overflow: none;
	white-space: pre-wrap;
}

// Inline
.small, time, cite, small, figcaption {
	font-size: $font-size-smaller;
	line-height: $base-line-smaller;
}
cite {
	color: rgba(0, 0, 0, .5);
}
figcaption {
	font-style: italic;
	padding-top: $base-unit;
	padding-bottom: $base-unit;
}
figure figcaption {
    position: relative;
    top: -$base-unit;
}
abbr[title], dfn[title] {
	border-bottom: 1px dotted rgba(0, 0, 0, .5);
	cursor: help;
}
mark, code, samp, kbd {
	padding: 4px 8px 2px 8px;
	display: inline-block;
	line-height: 1;
	border-radius: $base-unit;
	color: rgba(0, 0, 0, .85);
}
pre code {
	font-size: 100%;
	white-space: pre;
	padding: 0;
	background: none;
	line-height: $base-line;
}
mark {
	background-color: $mark-background-color;
}
code {
	background: $code-background-color;
}
var {
	color: rgba(0, 0, 0, .6);
	font-style: normal;
}
strong, b {
	line-height: 1;
}
samp {
	color: $samp-text-color;
	background: $samp-background-color;
}
kbd {
	border: $kbd-border;
}
sub,
sup {
    font-size: x-small;
	line-height: 0;
	margin-left: $base-unit;
	position: relative;
}
sup {
	top: -4px;
}
sub {
	bottom: -2px;
}