/*  Core Owl Carousel CSS File v1.3.3 */
/* clearfix */
.owl-carousel .owl-wrapper:after { content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }

/* display none until init */
.owl-carousel { display: none; position: relative; width: 100%; -ms-touch-action: pan-y; }
.owl-carousel .owl-wrapper { display: none; position: relative; -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-carousel .owl-wrapper-outer { overflow: hidden; position: relative; width: 100%; }

.owl-carousel .owl-wrapper-outer.autoHeight { transition: height 500ms ease-in-out; }
.owl-carousel .owl-item { float: left; }
.owl-controls .owl-page, .owl-controls .owl-buttons div { cursor: pointer; }
.owl-controls { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-tap-highlight-color: transparent; }

/* mouse grab icon */
.grabbing { cursor: url(grabbing.png) 8 8, move; }

/* fix */
.owl-carousel .owl-wrapper, .owl-carousel .owl-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); }

/*
*   Owl Carousel Owl Demo Theme 
* v1.3.3
*/
.owl-theme .owl-controls { text-align: center; }


/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons {position: absolute;top: 330px;left: 0;right: 0;height: 0;max-width: 960px;margin: 0 auto; z-index: 9}

.owl-theme .owl-controls .owl-buttons div { color: #FFF; display: inline-block; zoom: 1; *display: inline; /*IE7 life-saver */overflow: hidden; height: 70px; line-height: 70px;margin-top: 0px; width: 45px;position: relative; @include transition;}
.owl-theme .owl-controls.clickable .owl-buttons div:hover {text-decoration: none; }

.owl-theme .owl-controls .owl-buttons .owl-prev{float:left; left: -25px}
.owl-theme .owl-controls .owl-buttons .owl-next {right: 20px}
//.owl-theme .owl-controls .owl-buttons .owl-prev:hover{left:-28px}
//.owl-theme .owl-controls .owl-buttons .owl-next:hover {right:-28px}

.owl-theme .owl-controls .owl-buttons .owl-prev:before, .owl-theme .owl-controls .owl-buttons .owl-next:before{@include transition; }

.owl-theme .owl-controls .owl-buttons .owl-next:before { content: ''; display: inline-block; top: 50%; }
.owl-theme .owl-controls .owl-buttons .owl-next:before, .owl-theme .owl-controls .owl-buttons .owl-next:after {display: block;position: absolute;right: auto;left: 12px;width: 4px;height: 40px;background: #b67f00;content: '';transition: all 0.2s ease-in-out;}
.owl-theme .owl-controls .owl-buttons .owl-next:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.owl-theme .owl-controls .owl-buttons .owl-next:after { top: auto; bottom: 0px; transform: scaleX(1.3) translateX(5px) rotateZ(36deg); }

.owl-theme .owl-controls .owl-buttons .owl-next:hover:before, .owl-theme .owl-controls .owl-buttons .owl-next:hover:after { background: #00259a}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */


/* Styling Pagination*/

.owl-theme .owl-controls .owl-page { display: inline-block; zoom: 1; *display: inline; /*IE7 life-saver */ }
.owl-theme .owl-controls .owl-page span {display: block;width: 6px;height: 6px;margin: 5px 6px;filter: Alpha(Opacity=50); /*IE7 fix*/opacity: 0.5;border-radius: 20px;background: #bbb;}
.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span { filter: Alpha(Opacity=100); /*IE7 fix*/ opacity: 1; }



.gallery-slider.owl-theme .owl-controls .owl-pagination {margin-top: 10px;text-align: center;position: absolute;bottom: 80px;left: 0;right: 0;}
.gallery-slider.owl-theme .owl-controls .owl-page span {filter: Alpha(Opacity=50); /*IE7 fix*/opacity: 0.5;border-radius: 20px;background: #fff;}
.gallery-slider.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span { filter: Alpha(Opacity=100); /*IE7 fix*/ opacity: 1; }

/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers { height: auto; width: auto; color: #FFF; padding: 2px 10px; font-size: 12px; border-radius: 30px; }

/* preloading images */
.owl-item.loading { min-height: 150px; background: url(AjaxLoader.gif) no-repeat center center; }

/*  Owl Carousel CSS3 Transitions  v1.3.2 */
.owl-origin { -webkit-perspective: 1200px; -webkit-perspective-origin-x: 50%; -webkit-perspective-origin-y: 50%; -moz-perspective: 1200px; -moz-perspective-origin-x: 50%; -moz-perspective-origin-y: 50%; perspective: 1200px; }

/* fade */
.owl-fade-out { z-index: 10; animation: fadeOut .7s both ease; }
.owl-fade-in { animation: fadeIn .7s both ease; }

/* backSlide */
.owl-backSlide-out { animation: backSlideOut 1s both ease; }
.owl-backSlide-in { animation: backSlideIn 1s both ease; }

/* goDown */
.owl-goDown-out { animation: scaleToFade .7s ease both; }
.owl-goDown-in { animation: goDown .6s ease both; }

/* scaleUp */
.owl-fadeUp-in { animation: scaleUpFrom .5s ease both; }
.owl-fadeUp-out { animation: scaleUpTo .5s ease both; }

/* Keyframes */
/*empty*/
@keyframes empty { 0% { opacity: 1; } }
@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }
@keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }
@keyframes backSlideOut { 25% { opacity: .5; transform: translateZ(-500px); }
  75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); } }
@keyframes backSlideIn { 0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; transform: translateZ(-500px); }
  100% { opacity: 1; transform: translateZ(0) translateX(0); } }
@keyframes scaleToFade { to { opacity: 0; transform: scale(0.8); } }
@keyframes goDown { from { transform: translateY(-100%); } }
@keyframes scaleUpFrom { from { opacity: 0; transform: scale(1.5); } }
@keyframes scaleUpTo { to { opacity: 0; transform: scale(1.5); } }



.container-thumbs {margin: 0 auto;max-width: 960px;padding: 0 90px;position: absolute;bottom: 0;left: 0;right: 0;}

#sync1 { }
#sync1 .item{}
#sync1 .item img{width: 100%;display: block;}



#sync2 {}
#sync2 .owl-wrapper-outer { }
#sync2 .item img { display: block;}


#sync2 .item { color: #D6D6D8; cursor: pointer; position: relative; padding-top:30px; font-size:40px; line-height: 1; font-family: $font-family-2; font-weight: 900;transition: all 0.2s ease-in-out;}
#sync2 .item:hover {color: #00259a;}
#sync2 .synced .item { color: #00259a;}

#sync2 .item .team-nav-item {display:inline; position:relative; padding-top: 20px}
#sync2 .item span {display: inline-block;width: 36px;height: 18px;position: absolute;top: 10px;float: right;transition: all 0.2s ease-in-out;float: right;left: 50%;margin-left: -18px;opacity: 0;transform: rotate(90deg);}
#sync2 .item span:before, #sync2 .item span:after { display: block; position: absolute; right: auto; left: 0; width: 4px; height: 11px; background: #00259a; content: ''; transition: all 0.1s ease-in-out; }
#sync2 .item span:before { top: 0px; bottom: auto; transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); }
#sync2 .item span:after { top: auto; bottom: 0px; transform: scaleX(1.3) translateX(5px) rotateZ(36deg); }
#sync2 .synced .item span { opacity: 1; }


@media only screen and (max-width: 1180px) { 
.container-thumbs {max-width: 760px;padding: 0 90px;}
.owl-theme .owl-controls .owl-buttons {}
}

@media only screen and (max-width: 996px) { 
.container-thumbs {max-width: 560px;padding: 0 90px;}
.owl-theme .owl-controls .owl-buttons {}
}

@media only screen and (max-width: 785px) { 
.container-thumbs {max-width: 460px;padding: 0 90px;}
.owl-theme .owl-controls .owl-buttons {}
.owl-theme .owl-controls .owl-buttons div {font-size:40px;}
}

@media only screen and (max-width: 480px) { 
.container-thumbs {max-width: 360px;padding: 0 60px;}
.owl-theme .owl-controls .owl-buttons {bottom: 65px;max-width: 460px;}
.owl-theme .owl-controls .owl-buttons div {font-size:40px;}
.owl-theme .owl-controls .owl-buttons div {height: 60px;line-height: 60px;}

.owl-theme .owl-controls .owl-buttons .owl-prev{left: 0}
.owl-theme .owl-controls .owl-buttons .owl-next {right: 0}
}
