// Breakpoints
$breakpoint-small: 768px !default;
$breakpoint-medium: 1024px !default;
$breakpoint-large: 1200px !default;

// FontFamily
$font-family: 'Merriweather', serif !default;
$font-family-2: 'Yantramanav', sans-serif !default;
$heading-font-family: $font-family !default;
$monospace-font-family: Consolas, Monaco, "Courier New", monospace !default;

// Baseline
$base-unit: 4px !default;
$base-line: 24px !default;
$base-line-small: 20px !default;
$base-line-smaller: 16px !default;

// Grid
$grid-columns: 12 !default;
$grid-width: 1128 !default; // pixels
$grid-gutter: $base-line !default;
$grid-gutter-integer: ($grid-gutter / ($grid-gutter * 0 + 1)) !default;
$grid-gap: 60px !default;

// Z Indexes
$z-over-content: 100 !default;
$z-over-control: 200 !default;
$z-over-page: 300 !default;
$z-over-screen: 400 !default;
$z-over-all: 500 !default;

// Text font size
$font-size-regular: 16px !default;

$font-size-big: $font-size-regular + 2px !default;
$font-size-small: $font-size-regular - 2px !default;
$font-size-smaller: $font-size-regular - 4px !default;

// UI font size
$ui-font-size: $font-size-small !default;
$ui-font-size-small: $font-size-smaller !default;
$ui-font-size-big: $font-size-big !default;


// Spaces
$heading-margin-bottom: 0px !default;
$paragraph-margin-bottom: 16px !default;

// Color
$body-text-color: #31333a !default;
$heading-text-color: #000 !default;
$samp-text-color: #fff !default;
$input-text-color: #333 !default;
$input-append-text-color: #555 !default;

// Color States
$color-success: #60ae4d !default;
$color-error:   #f34248 !default;
$color-warning: #fed443 !default;

$color-default: #31333a !default;
$color-primary: #0E1B3F !default;
$color-secondary: #4ec4b8 !default;
$color-light: #E6E8EB !default;

// Headings
$title-font-size: 60px !default;
$h1-font-size: 23px !default;
$h2-font-size: 35px !default;
$h3-font-size: 33px !default;
$h4-font-size: 25px !default;
$h5-font-size: 17px !default;
$h6-font-size: 12px !default;

$title-line-height: 64px !default;
$h1-line-height: 1 !default;
$h2-line-height: 1.1 !default;
$h3-line-height: 1.2 !default;
$h4-line-height: $base-line !default;
$h5-line-height: $base-line !default;
$h6-line-height: 20px !default;

$title-color : #000 !default;
$h1-color: $color-primary !default;
$h2-color: $color-secondary !default;
$h3-color: $color-secondary !default;
$h4-color: $color-primary !default;
$h5-color: #5B87DA !default;
$h6-color: #000 !default;

// Links
$link-color: $color-primary !default;
$link-hover-color: $color-secondary !default;



// Background
$body-background-color: #fff !default;
$pre-background-color: #f8f8f8 !default;
$mark-background-color: $color-warning !default;
$code-background-color: $color-default !default;
$samp-background-color: lighten($color-primary, 7%) !default;
$highlight-background-color: rgba($color-primary, .075) !default;

// Kbd
$kbd-border: 1px solid rgba(0, 0, 0, .1) !default;

// Table
$table-striped-background-color: #f4f4f4 !default;
$table-border: 1px solid #eee !default;

// Label
$label-font-size: $ui-font-size !default;
$label-color: #666 !default;
$label-font-weight: normal !default;

// Input
$input-disabled-opacity: 0.75 !default;
$input-disabled-color: rgba(0, 0, 0, .5) !default;
$input-border-size: 5px !default;
$input-border-color: #E6EDF9 !default;
$input-focus-border-color: #00259a !default;
$input-background-color: #fff !default;
$input-focus-background-color: #fff !default;
$input-append-background-color: #eee !default;
$input-padding: 8px 10px !default;
$input-padding-big: 16px 10px !default;

// Fieldset
$fieldset-border-color: rgba(0, 0, 0, .1) !default;
$fieldset-border-size: 1px !default;

// Button
$button-background-color: #eee !default;
$button-font-weight: 300 !default;
$button-outline-font-weight: 300 !default;
$button-outline-border-size: 1px !default;
$button-padding-small: 6px 20px !default;
$button-padding: 10px 28px !default;
$button-padding-big: 20px 48px !default;

// Height
$ui-height-small: 32px !default;
$ui-line-height-small: 32px !default;
$ui-height: 40px !default;
$ui-line-height: 38px !default;
$ui-height-big: 48px !default;
$ui-line-height-big: 46px !default;

// Overlay
$overlay-background-color: rgba(255, 255, 255, .9) !default;