.label {
	font-size: 10px;
	font-weight: normal;
	display: inline;
	line-height: 1;
	top: -1px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	border-radius: 4px;
	position: relative;
	vertical-align: middle;
	color: #000;
	background: $color-default;
	padding: 2px 8px;
	letter-spacing: .01em;
	& a,
	& a:hover {
		color: #000;
		text-decoration: none;
	}
}
.label.primary {
    @include label(#fff, $color-primary);
}
.label.error {
    @include label(#fff, $color-error);
}
.label.success {
    @include label(#fff, $color-success);
}
.label.warning {
    @include label(#000, $color-warning);
}
.label.inverted{
    @include label(#000, #fff);
}
.label.outline {
	background: none;
	padding: 1px 7px;
	border: 1px solid rgba(0, 0, 0, .2);
	&,
	& a {
        color: rgba(0, 0, 0, .65);
	}
}

// Badge
.badge {
    padding: 2px 6px;
    border-radius: $base-line;
}
.badge.outline {
    padding: 1px 5px;
}

// Tags
.label.tag {
    padding: 0;
    background: none;
    font-weight: bold;
    letter-spacing: .02em;
}
.label.tag.primary {
    &,
    & a {
        color: $color-primary;
    }
}
.label.tag.error {
    &,
    & a {
        color: $color-error;
    }
}
.label.tag.success {
    &,
    & a {
        color: $color-success;
    }
}
.label.tag.warning {
    &,
    & a {
        color: $color-warning;
    }
}
.label.tag.inverted {
    &,
    & a {
        color: #fff;
    }
}

// Utility
.button:hover {
    & .badge {
        opacity: .6;
    }
}
h6 {
    & .label {
        top: -3px;
    }
}