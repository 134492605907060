::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #666;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #666;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #666;
}
:-moz-placeholder { /* Firefox 18- */
  color: #666;
}

input,
select,
textarea,
fieldset {
	font-family: $font-family;
	font-size: $ui-font-size;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="date"],
input[type="tel"],
input[type="url"],
input[type="password"] {
	height: $ui-height;
	padding: $input-padding;
	vertical-align: middle;
	-webkit-appearance: none;
	background: $input-background-color;
	border:none;
	border-bottom: $input-border-size solid $input-border-color;
	width: 100%;
	@include transition();

}

select,
textarea {
	display: block;
	width: 100%;
	line-height: 1.75;
	padding: $input-padding;
	background: $input-background-color;
	border:none;
	border-bottom: $input-border-size solid $input-border-color;
	@include transition();
}

select:not([multiple]) {
	cursor: pointer;
	height: $ui-height;
	vertical-align: middle;
}

label {
	vertical-align: middle;
	color: #666;
	padding: $input-padding;
}

textarea:focus,
select:focus,
input:focus {
	outline: none;
	background: $input-focus-background-color;
	border-color: $input-focus-border-color;
}

textarea:disabled,
select:disabled,
input:disabled,
textarea.disabled,
select.disabled,
input.disabled {
	resize: none;
	opacity: $input-disabled-opacity;
	cursor: default;
	font-style: italic;
	color: $input-disabled-color;
}

// Fieldset
fieldset {
	padding: 39px 32px;
	margin-bottom: $base-line;
	border: $fieldset-border-size solid $fieldset-border-color;
	border-radius: 4px;
}
legend {
	font-weight: bold;
	font-size: $ui-font-size-small;
	text-transform: uppercase;
	padding: 0 1em;
	margin-left: -1em;
	top: 2px;
	position: relative;
	line-height: 0;
}

// Collection
.form {

    margin-bottom: 0;

    & .form-item {
        margin-bottom: $base-line;
    }

	& input[type="email"],
	& input[type="number"],
	& input[type="search"],
	& input[type="text"],
	& input[type="date"],
	& input[type="tel"],
	& input[type="url"],
	& input[type="password"],
	& select,
	& textarea {
		display: block;
		width: 100%;
	}

	& input,
	& textarea,
	& select {
		&.width-50 { width: 50%; }
	}

	& label {
		font-size: $label-font-size;
		color: $label-color;
		font-weight: $label-font-weight;
		display: block;
	}

	& .checkboxes label,
	& label.checkbox {
		text-transform: none;
		font-weight: normal;
		cursor: pointer;
		color: inherit;
		font-size: $ui-font-size + 1px;
	}

	& .checkboxes label {
		margin-bottom: 8px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	& .checkboxes-inline {
		display: block;
		& label {
			font-size: inherit;
			color: inherit;
			display: inline-block;
			margin-right: 16px;
			text-transform: none;
			font-weight: normal;
		}
	}

	& span.error,
    & div.error,
	& span.success,
    & div.success {
		text-transform: none;
		font-weight: normal;
		font-size: 12px;
    }

	& span.error,
	& span.success {
		margin-left: .4rem;
	}
}

// Inline
.form-inline {

	& textarea,
	& select,
	& input[type="email"],
	& input[type="number"],
	& input[type="search"],
	& input[type="text"],
	& input[type="date"],
	& input[type="tel"],
	& input[type="url"],
	& input[type="password"] {
		width: auto;
		display: inline-block;
	}
}

// Sizes



input.big,
select.big {
	font-size: $ui-font-size-big;
	height: $ui-height-big;
}
input.big {
	padding: $input-padding-big;
}
select.big {
	padding: 6px 10px;
}

// Descriptions
.desc {
	text-transform: none;
	margin-top: 4px;
	color: rgba(0, 0, 0, .5);
	font-size: 12px;
	line-height: $base-line-small;
	font-weight: normal;
}
ul.desc {
	margin-bottom: 8px;
}
span.desc {
	margin-left: 4px;
	line-height: $base-line-small;
}

// States
input.error,
textarea.error,
select.error {
	margin-top: -1px;
	background: none;
	background-color: rgba($color-error, .1);
	border: $input-border-size solid $color-error;
}
input.success,
textarea.success,
select.success  {
	margin-top: -1px;
	background: none;
	background-color: rgba($color-success, .1);
	border: $input-border-size solid $color-success;
}

// Append & Prepend
.controls {
	@include flex;

	& input {
		@include flex-item-one;
		margin-bottom: 0 !important;
	}

	& .button,
	& span {
		@include flex-item-shrink;

    }

	& span {
		font-weight: normal;
		background-color: $input-append-background-color;
		padding: 0 12px;
		color: $input-append-text-color;
		font-size: $font-size-smaller;
		line-height: $ui-height;
		white-space: nowrap;
	}

	& .button {
		border-radius: 0 4px 4px 0;
	}
}

// Responsive
@media (max-width: $breakpoint-small) {

	.form {
		& input,
		& textarea,
		& select {

			// disable autozoom
			font-size: 16px;

			&.width-50 { width: 100%; }
		}
	}
}
