
.carousel-full .carousel-cell {width: 100%;}
.carousel-full img {max-width: 100%;width: 100%;}

.carousel-cut img {max-width: auto;width: auto;  height: 620px;margin: 0 0px;}


@media only screen and (max-width:1400px) {
  .carousel-cut img { height: 520px;}
}


@media only screen and (max-width:1100px) {
  .carousel-cut img { height: 420px;}
}

@media only screen and (max-width:960px) {
  .carousel-cut img { height: 360px;margin: 0 0px;}
}

@media only screen and (max-width:768px) {
  .carousel-cut .carousel-cell {width: 80%;margin: 0 0px;}
  .carousel-cut img { height: 300px;margin: 0 0px;}
}


@media only screen and (max-width:660px) {
  .carousel-cut img { height: 260px;}
}

@media only screen and (max-width:580px) {
  .carousel-cut img { height: 200px}
}

@media only screen and (max-width:450px) {
  .carousel-cut img { height: 180px}
}

@media only screen and (max-width:370px) {
  .carousel-cut img { height: 170px}
}

@media only screen and (max-width:350px) {
  .carousel-cut img { height: 160px}
}



/* flickity-fade */
.flickity-enabled.is-fade .flickity-slider > * { pointer-events: none; z-index: 0; }
.flickity-enabled.is-fade .flickity-slider > .is-selected { pointer-events: auto; z-index: 1; }