@media only screen and (max-width: 768px) { 
a.animated-button {	margin-top: 20px;}
}



.btn-invert { background:transparent; border:1px solid rgba(255,255,255,0.2); color:#fff; opacity:1;padding: 12px 30px; font-weight: 500; position: relative; display: flex; align-items:center; justify-content:space-between; text-decoration: none;}
.btn-invert:hover { background: transparent !important;color: #fff; border-color:rgba(255,255,255,1); }

.btn-invert span {display:inline-block;width: 36px;height: 18px;position:relative;float: right;left: 15px; @include transition(all .2s ease-in-out);transform:scale(0.8)}
.btn-invert:hover span { left:20px}
.btn-invert span:before, .btn-invert span:after {display: block;position: absolute;right: auto;left: 0;width: 3px;height: 11px;background: #b67f00;content: '';transition: all 0.3s ease-in-out;}
.btn-invert:hover span:before, .btn-invert:hover span:after {background: #fff;}


.btn-invert span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.btn-invert span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}



.btn { background:transparent; border:1px solid #5B87DA; color:#5B87DA; opacity:1;padding: 10px 30px; font-size: 14px; font-weight: 500; position: relative; display: flex; align-items:center; justify-content:space-between; text-decoration: none;}
.btn:hover { background: transparent !important;color: #b67f00; border-color:#b67f00; }

.btn span {display:inline-block;width: 25px;height: 18px;position:relative;float: right;left: 15px; @include transition(all .2s ease-in-out);transform:scale(0.8)}
.btn:hover span { left:20px}
.btn span:before, .btn span:after {display: block;position: absolute;right: auto;left: 0;width: 3px;height: 11px;background: #b67f00;content: '';transition: all 0.3s ease-in-out;}
.btn:hover span:before, .btn:hover span:after {}


.btn span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.btn span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}





// Default
button,
.button {
	font-family: $font-family;
	font-size: $ui-font-size;
	font-weight: $button-font-weight;
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	line-height: 1.125rem;
	min-height: $ui-height;
	padding: $button-padding;
	border: $button-outline-border-size solid transparent;
	vertical-align: middle;
	-webkit-appearance: none;
	color: #000;
	background-color: $button-background-color;
	border-radius: 0;
}
input.button {
	height: auto;
}
button:hover,
.button:hover {
    outline: none;
	color: rgba(0, 0, 0, .6);
	text-decoration: none;
}

.button:disabled,
.button.disabled {
	cursor: default;
	font-style: normal;
	opacity: .5;
	&:hover {
		color: #000;
		box-shadow: none;
	}
}

// Outline
.button.outline {
	background: none;
	font-weight: $button-outline-font-weight;
}
.button.outline:hover {
	color: rgba(0,0,0,.6);
	border-color: rgba(0,0,0,.2);
	box-shadow: none;
}
.button.outline,
.button.outline:disabled:hover,
.button.outline.disabled:hover {
	color: rgba(0,0,0,.8);
	border-color: rgba(0,0,0,.4);
}

// Sizes
.button.small {
	font-size: $ui-font-size-small;
	min-height: $ui-height-small;
	padding: $button-padding-small;
	&.upper {
		font-size: $ui-font-size-small - 2px;
	}
}
.button.big {
	font-size: $ui-font-size-big;
	min-height: $ui-height-big;
	padding: $button-padding-big;
	&.upper {
		font-size: $ui-font-size-big - 5px;
	}
}

// Upper
.button.upper {
	font-size: $ui-font-size - 3px;
}

// Primary
.button.primary {
	@include button(#fff, $color-primary);
}

// Inverted
.button.inverted {
	@include button(#000, #fff);
}

// Grouped
.buttons.group {

	display: inline-block;
	vertical-align: bottom;

	& .button {
		float: left;
		margin-left: -1px;
		border-radius: 0;

		&:first-child {
			border-radius: 4px 0 0 4px;
		}
		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}
}



.button-light { background:transparent; border:2px solid rgba(255,255,255,0.2); color:#fff; opacity:1}
.button-light:hover { background: transparent !important;color: #fff; border:2px solid rgba(255,255,255,1); }

.button-light span {display:inline-block;width: 36px;height: 18px;position:relative;float: right;left: 25px;@include transition(all .2s ease-in-out);}
.button-light:hover span { left:30px}
.button-light span:before, .button-light span:after {display: block;position: absolute;right: auto;left: 0;width: 4px;height: 11px;background: #00259a;content: '';transition: all 0.1s ease-in-out;}
.button-light span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.button-light span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}

.button-gold { background:transparent; border: 2px solid rgba(182, 127, 0, 0.5); color:#fff!important; opacity:1;@include transition(all .2s ease-in-out);font-size: 17px;min-height: 48px;padding: 17px 30px; margin-top: 30px}
.button-gold:hover { background: #b67f00 !important;color: #fff; border: 2px solid rgba(182, 127, 0, 1);  }

.button-gold span {display:inline-block;width: 36px;height: 18px;position:relative;float: right;left: 25px;@include transition(all .2s ease-in-out);}
.button-gold:hover span { left:30px}
.button-gold span:before, .button-gold span:after {display: block;position: absolute;right: auto;left: 0;width: 4px;height: 11px;background: #fff;content: '';transition: all 0.1s ease-in-out;}
.button-gold span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.button-gold span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}




.button-dark { background:transparent; border: 2px solid rgba(91,135,218,0.5); color:#00259a; opacity:1; font-weight: 500; margin-top: 40px}
.button-dark:hover { background: transparent !important;color: #00259a; border:2px solid rgba(91,135,218,1); }

.button-dark span {display:inline-block;width: 36px;height: 18px;position:relative;float: right;left: 25px;@include transition(all .2s ease-in-out);}
.button-dark:hover span { left:30px}
.button-dark span:before, .button-dark span:after {display: block;position: absolute;right: auto;left: 0;width: 4px;height: 11px;background: #00259a;content: '';transition: all 0.1s ease-in-out;}
.button-dark span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.button-dark span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}