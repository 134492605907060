/* Preloader */
#preloader { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: #fff; z-index: 99999; }

/* Standard Overwrites  */
img {display: block;}
body {-webkit-font-smoothing: antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0.004);overflow-x: hidden!important;}
html, body, #main, .page-content, .content, .wrapper, #skrollr-body { /*height: 100%*/}

/* Layout Elements  */
.align-middle {@include flex;@include flex-middle;}
.align-center {@include flex;@include flex-center;}
.align-bottom {@include flex;@include flex-bottom;}

.position-relative { position: relative;}

.align-middle-translate {position: relative; top: 50%;transform: translateY(-50%);}

.table { display: table;width: 100%}

.width-100 { width: 100%}
.min-height-100vh { min-height: 100vh;}

.page-content { position: relative;}

.container {margin:0 auto;max-width: 1160px;padding: 0 30px;}
.container-no-pad { margin: 0 auto; max-width: 1100px; padding: 0 0px; }

.container-pad { padding-top: 90px; padding-bottom: 90px;}

.container-pad-20  { padding-top: 20px; padding-bottom: 20px;}
.container-pad-40  { padding-top: 40px; padding-bottom: 40px;}
.container-pad-70  { padding-top: 70px; padding-bottom: 70px;}
.container-pad-top { padding-top: 90px;}
.container-pad-top-70 { padding-top: 70px;}
.container-pad-top-40 { padding-top: 40px;}

.pad-top-20 {padding-top: 20px;}


.container-pad-bottom-70 { padding-bottom: 70px;}

.container-pad-bottom {padding-bottom: 90px;}
.container-pad-bottom-2 {padding-bottom: 30px;}

.pad-top-0 { padding-top: 0!important;}
.pad-bottom-0 { padding-bottom: 0!important;}
.pad-bottom-10 { padding-bottom: 10px!important;}
.pad-bottom-70 { padding-bottom: 70px!important;}


.mar-bottom-0 { margin-bottom: 0!important;}


.item-pad-right {float: right; max-width: 580px; width:100%;padding-right: 70px;padding-top: 70px;padding-left: 30px;padding-bottom: 50px;}
.item-pad-left {max-width: 580px; width:100%;padding-left: 70px;padding-top: 70px;padding-right: 30px;padding-bottom: 50px;}

.item-pad-left2 { max-width: 550px; width: 100%; padding-left: 70px; padding-top: 70px; padding-right: 30px; padding-bottom: 50px; }
.item-pad-left2 p { padding-bottom: 30px;}

.center-mobile-pad { margin: 0 0 60px 0}

 .desktop-indent-left { position: absolute; left: 50%; max-width: 500px; margin-left: -300px; margin-bottom: 60px; bottom: 0;}


/* Overwrites */

 
/* Position Elements  */
.background-cover { background-size: cover; background-position: center;}
.background-contain { background-size: contain}
.background-center { background-position: center;}
.background-right { background-position: right;} 
.no-touch .background-fixed { background-attachment: fixed;}
.absolute-full{position: absolute;top:0; bottom: 0; left: 0; right: 0}

  _:-ms-lang(x), _:-webkit-full-screen, .no-touch .background-fixed {background-attachment: scroll;}



/* Color Elements  */
.bg-black { background-color: #000}
.bg-black, .bg-black h1,  .bg-black h2, .bg-black h3, .bg-black h4, .bg-black h5, .bg-black .list-facilities li span, .bg-black blockquote { color:#fff}
.bg-black h1 {padding: 0 0 0 0;}
.bg-black h1 a { text-decoration: none; color:#fff}
.bg-black h1 a:hover {    color: #5b87da;}

.bg-black p, .bg-black li{color: rgba(255,255,255,0.7);}
.bg-black h5 {font-size: 22px;line-height: 1.3;}

.bg-black em { padding-top: 20px; color: #fff; display: inline-block; font-size: 14px}

.bg-blue { background-color: #5b87da}
.bg-blue, .bg-blue h1,  .bg-blue h2, .bg-blue h3, .bg-blue h4, .bg-blue h5, .bg-blue .list-facilities li span { color:#fff}
.bg-blue h1 {padding: 0 0 0 0;}
.bg-blue h1 a { text-decoration: none; color:#fff}
.bg-blue h1 a:hover {    color: #5b87da;}
.bg-blue p, .bg-blue li{color: rgba(255,255,255,0.7);}
.bg-blue h5 {font-size: 22px;line-height: 1.3;}

.bg-white { background: #fff}







.hover {-webkit-user-select: none;-webkit-touch-callout: none; }

.skrollr-deck { position: relative; box-sizing: border-box; min-height: 100%; height: 100vh; }
.slide-container { position: relative; z-index: 9; }
.hsContainer { display: table; table-layout: fixed; width: 100%; height: 100%; overflow: hidden; position: relative; }

.hsContent { margin: 196px auto 0 auto; display: table-cell; vertical-align: middle; bottom: 60px; max-width: 100%;  }



.hsContent.text-white { color: #fff; }
.deck-text { position: fixed;width: 100%;top: 0;}
.page-bottom .deck-text, .page-bottom .deck-bottom { position: absolute; }
.page-bottom .background-fixed { /*background-attachment: inherit!important;*/}
/* IE home cards issue */
  _:-ms-lang(x), _:-webkit-full-screen, .no-touch .deck-img.background-fixed {background-attachment: scroll;}

.deck-bottom {position: fixed;width: 100%;bottom: 60px;left: 0;}





#slide-1, #slide-1 .deck-img{ z-index: 1 }
#slide-2, #slide-2 .deck-img {z-index: 2}
#slide-3, #slide-3 .deck-img {z-index: 3}
#slide-4, #slide-4 .deck-img {z-index: 4}





.down-arrow {display: inline-block;width: 80px;height: 60px;margin-left: -46px;left: 50%;position: relative; bottom:0}
.down-arrow:hover { bottom:-5px; }

.down-arrow:before, .down-arrow:after {display: block;position: absolute;right: auto;width: 5px;height: 50px;background: #00259a;content: '';transition: all 0.3s ease-in-out;}
.down-arrow:hover:before, .down-arrow:hover:after { opacity: 1; }

.down-arrow:before {top: 0;bottom: auto;left: 14px;transform: scaleX(1.3) translateX(5px) rotateZ(-40deg);}
.down-arrow:after {top: auto;left: 52px;top: 0;transform: scaleX(1.3) translateX(5px) rotateZ(40deg);}

.down-arrow:hover:before, .down-arrow:hover:after { background:#b67f00}

.footer-holder { position: fixed; width: 100%; max-width: 1300px; margin: 0 auto; left: 0; right: 0; z-index: 1; z-index: 99; bottom: 140px; height: 0; }
.footer {position:relative;bottom: 75px;    height: 0;}
.footer ul { list-style:none; padding:0; margin:0; width:50px; text-align:center}
.footer ul li {margin-top:10px}
.footer ul li svg {}

.footer ul li svg#svg_twitter, .nav-bottom svg#svg_twitter {width: 19px;}
.footer ul li svg#svg_linkedin, .nav-bottom svg#svg_linkedin {width: 16px;}
.footer ul li svg#svg_cv {width:40px;}

.nav-bottom  a svg path { fill:#fff; @include transition(all .3s ease-in-out);}
.nav-bottom a:hover svg path {fill:#5b87da}

.footer ul li a svg path { fill:#00259a; @include transition(all .3s ease-in-out);}
.footer ul li a:hover svg path{fill:#b67f00}

.back-to-top {  margin-top: 20px!important}
.back-to-top a { position: relative; color: #00259a; opacity: 0; cursor: default;}
.back-to-top a:hover {color: #b67f00}

.back-to-top a span {display:inline-block;width: 36px;height: 18px;position:absolute;top: -20px;float: right;@include transition(all .2s ease-in-out);float: right;left: 50%;margin-left: -20px;transform: rotate(-90deg);}
.back-to-top a:hover span {}

.back-to-top a span:before, .back-to-top a span:after {display: block;position: absolute;right: auto;left: 0;width: 4px;height: 11px;background: #00259a;content: '';@include transition(all .2s ease-in-out);}
.back-to-top a:hover span:before, .back-to-top a:hover span:after {background: #b67f00;}

.back-to-top a span:before {top: 0px;bottom: auto;transform: scaleX(1.3) translateX(5px) rotateZ(-36deg);}
.back-to-top a span:after {top: auto;bottom: 0px;transform: scaleX(1.3) translateX(5px) rotateZ(36deg);}

.back-to-top a strong {position: absolute;left: 0px;padding-left: 30px;top: -9px;white-space: nowrap; font-size: 19px; font-family: $font-family-2;font-weight:900; }

.back-to-top.back-to-top-in-view a{ opacity: 1; cursor: pointer;}

footer {}
.footer-inner { padding-top: 220px}

/* All */
.nav { font-family: $font-family-2;font-weight:900; }
.nav ul { *zoom: 1; list-style: none; margin: 0; padding: 0; min-width: 260px; }
.nav ul:before, .nav ul:after { content: ""; display: table; }
.nav ul:after { clear: both; }
.nav ul > li { float: left; position: relative; font-size: 28px; margin-right: 40px; }
.nav ul > li:hover a { color: #5b87da }
.nav ul > li:hover li a { color: #fff }
.nav ul > li:hover li a:hover { color: #b67f00; padding-left: 30px; }
.nav a { display: block; color: #fff; text-decoration: none; }
.nav a:hover { }
.nav li ul { }
.nav li ul li { font-size: 19px; float: none }
.nav li ul a { }
.nav li ul a:hover { }

/* Slide Down
============================== */
.nav3 ul > li:hover ul li, .nav3 ul > li.mobile_hover ul li { height: 36px; }
.nav3 li ul { position: absolute; left: 0; padding-top: 10px; z-index: 1; }
.nav3 li ul li { overflow: hidden; height: 0; -webkit-transition: height 200ms ease-in; -moz-transition: height 200ms ease-in; -o-transition: height 200ms ease-in; transition: height 200ms ease-in; }
.nav li ul a { @include transition(all .1s ease-in-out); }
.nav li ul a:before, .nav li ul a:after { display: block; position: absolute; right: auto; left: 0; width: 4px; height: 11px; background: #b67f00; content: ''; opacity: 0; @include transition(all .1s ease-in-out); }
.nav li ul a:hover:before, .nav li ul a:hover:after { opacity: 1 }
.nav li ul a:before { top: 7px; bottom: auto; -moz-transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); -ms-transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); -webkit-transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); transform: scaleX(1.3) translateX(5px) rotateZ(-36deg) }
.nav li ul a:after { top: auto; bottom: 11px; -moz-transform: scaleX(1.3) translateX(5px) rotateZ(36deg); -ms-transform: scaleX(1.3) translateX(5px) rotateZ(36deg); -webkit-transform: scaleX(1.3) translateX(5px) rotateZ(36deg); transform: scaleX(1.3) translateX(5px) rotateZ(36deg) }
.footer-bottom-address { position: absolute; bottom: 75px; width: 100%; color: rgba(255, 255, 255, 0.5); font-size: 13px; }
.footer-bottom-address a { text-decoration: none; color: rgba(255, 255, 255, 0.5); }
.footer-bottom-address a[href="tel"] {cursor: text;}

#brodosplit { width:100%; max-width: 360px}
#brodosplit path, #brodosplit rect, #brodosplit polygon  { fill:#fff}

#svg-play { width: 60px}
.watch-video span { position: relative;top:-14px}

#svg-play path, #svg-play polygon{fill:#fff; @include transition;}
a:hover #svg-play path, a:hover #svg-play polygon { fill:#5b87da}


.block-lines{ overflow:hidden;  margin-top:60px;  margin-bottom:60px}
.block-lines ul{display:table;width:100%;position:relative;right:-3px;bottom:-2px; list-style: none;padding: 0;margin: 0;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.block-lines ul li{width:33.333333%;float:left;padding:30px;border-bottom: 1px solid rgba(255,255,255,0.15);border-right:1px solid rgba(255,255,255,0.15);margin-bottom: 0px!important;}

.block-lines ul li p { padding: 20px 0 0 0;margin: 0;}

.h1-small { font-size: 73px;line-height: 0.8;padding-bottom: 7px!important;}
.h1-big {font-size: 108px;line-height: 94px;}
.h1-dark-blue {color: #00259a!important}

#svg-1924 {max-width: 230px;}
#svg-1924 path{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}

#svg-35 {max-width: 130px;padding-top: 28px;}
#svg-35 path{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}

#our-team { margin-top: -140px;position: relative;}
.blue-indent {background: #00259a;padding: 60px;color: rgba(255, 255, 255, 0.63);position: relative;z-index: 2;}
.outdent-fix {}

#sync1-holder {position: relative;margin-top: -142px;width: 100%;}

.team-indent {position: relative;margin-top: 330px;padding-top: 0px;}
.team-indent h2 { color: #31333a; padding: 8px 0 5px 0;margin: 0;}
.team-indent h3 { color: #31333a; padding: 0 0 50px;margin: 0; font-weight: 300}

.team-quote { max-width: 410px;margin: 50px auto;color: #b67f00;font-size: 21px;font-weight: 600;line-height: 1.7;}

.owl-nav-holder {position: absolute;top: 282px;width: 100%;}

.logo-chelsea-group {    padding-top: 70px;}
.logo-chelsea-group img {max-width: 150px; margin-bottom: 120px;}

.cta { position: relative;z-index: 7;    display: block;}

.cta-block { position: absolute; top: 0; left: 0; width: 325px; height: 220px; background: #00259a; z-index: 2 }

.cta-gallery .cta-block {width: 350px;height: 120px;}

.cta h1 {position: absolute;top: 55px;left: 70px;color: #fff;z-index: 3; white-space: nowrap;}
.cta.cta-gallery h1 {top: 48px; display: flex; align-items:center;padding: 0;}

.cta h1 small {color: rgba(255, 255, 255, 0.5);font-family: $font-family; font-size: 21px; padding-right: 10px; font-weight: 500}
.cta h1 em {font-family: $font-family;font-size: 15px; font-style: normal;} 
.cta.cta-gallery h1 b {display: block;width: 17px;height: 25px;margin-right: 20px;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.457' height='24.671' viewBox='0 0 14.457 24.671'%3E %3Cpath id='Path_251' data-name='Path 251' d='M22.55%2C0%2C11.275%2C11.275%2C0%2C0' transform='translate(1.061 23.61) rotate(-90)' fill='none' stroke='%23b67f00' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");
background-repeat: no-repeat;
background-position: center;
display: block;
transition:all 0.4s;
left: 0!important;
position: relative;

}

.cta.cta-gallery:hover h1 b {
	left: 4px!important;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.457' height='24.671' viewBox='0 0 14.457 24.671'%3E %3Cpath id='Path_251' data-name='Path 251' d='M22.55%2C0%2C11.275%2C11.275%2C0%2C0' transform='translate(1.061 23.61) rotate(-90)' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='3'/%3E %3C/svg%3E ");
}

.cta h1 span { display: inline-block; height: 70px; line-height: 70px; margin-top: 0px; width: 45px; position: relative; bottom: -20px; left: 0; @include transition(all .2s ease-in-out); opacity: 0 }

.cta:hover h1 span {left: 5px; opacity: 1}
.cta h1 span:before { content: ''; display: inline-block; top: 50%; }
.cta h1 span:before, .cta h1 span:after { display: block; position: absolute; right: auto; left: 12px; width: 4px; height: 40px; background: #fff; content: ''; @include transition(all .2s ease-in-out); }
.cta h1 span:before { top: 0px; bottom: auto; transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); }
.cta h1 span:after { top: auto; bottom: 0px; transform: scaleX(1.3) translateX(5px) rotateZ(36deg); }
.cta:hover h1 span:before, .cta:hover h1 span:after { background: #b67f00}

.cta-layer { position: absolute; top:0; bottom: 0; left: 0; right: 0; background: rgba(0,0,0,0); @include transition(all .2s ease-in-out); }
a:hover .cta-layer { background: rgba(0,0,0,0.7);}

.cta.cta-full .cta-block {top: auto; left: 50%;  bottom: 0;}
.cta.cta-full h1 {top:auto;bottom: 0;margin-left: 70px; left:50%; }

.cta-exterior {  }
.cta-exterior .cta-block { left: auto; right: 0; top: auto; bottom: 0;  background: #5b87da;}
.cta-exterior h1 {top: auto;bottom: 0px;left: auto;right: 180px;}

.cta-exterior h1 span {bottom: -20px; right: 0; left:auto; }
.cta-exterior h1 span:before, .cta-exterior h1 span:after { left: auto; right: 12px; content: '';}

.cta-exterior h1 span:before { top: 0px; bottom: auto; transform: scaleX(1.3) translateX(5px) rotateZ(36deg); }
.cta-exterior h1 span:after { top: auto; bottom: 0px; transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); }
.cta-exterior:hover h1 span { right: 5px; left:auto }

.cta-interior { }
.cta-interior h1 {left: 180px;}

.cta-content {position: absolute;top: 290px;max-width: 370px;left: 70px;opacity: 0;z-index: 3; @include transition(all .2s ease-in-out);}

.cta-exterior .cta-content {top: auto;bottom: 290px;}

.touch .cta-exterior .cta-layer, .touch .cta-interior .cta-layer { background: rgba(0,0,0,0.7);} 
.touch .cta-content { opacity: 1}


a:hover .cta-content  {opacity: 1}
.cta-content p{color: rgba(255, 255, 255, 0.7);}

.hero-secondary {}
.hero-secondary p { color: rgba(0, 0, 0, 0.7);}

.hero-secondary.hero-dark h2 { color: #fff}
.hero-secondary.hero-dark p { color: rgba(255, 255, 255, 0.7);}

.block-lines-2{ overflow:hidden;  margin-top:60px;  margin-bottom:60px}
.block-lines-2 ul{display:table;width:100%;position:relative;right:-3px;bottom:-2px; list-style: none;padding: 0;margin: 0;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.block-lines-2 ul li{width:20%;float:left;padding:10px 30px;border-bottom: 1px solid rgba(91, 135, 218, 0.3);border-right: 1px solid rgba(91, 135, 218, 0.3);margin-bottom: 0px!important;}
.svg-holder { height: 120px; display: table; width: 100%}
.svg-inner { display: table-cell;vertical-align: middle;}
.svg-holder .svg-inner img { margin:0 auto}

.block-lines-2 p {color: #5b87da;font-size: 20px;position: relative;font-family: $font-family-2;font-weight:900; text-align: center;; line-height: 1.1;}
.block-lines-2 small {font-family: $font-family; display: block;color: #31333a; font-size: 13px; text-align: center;}




.block-lines-3 { margin-top:30px;  margin-bottom:60px}
.block-lines-3 ul{display:flex;width:calc(100% + 50px);position:relative;list-style: none;padding: 0;margin: 0;flex-wrap: wrap; margin-left: -50px;margin-right: -50px; justify-content:center}
.block-lines-3 ul li{width:calc(20% - 50px);float:left;padding:10px 0;margin-bottom: 0px!important;margin-left: 50px;}
.block-lines-3 ul li .svg-holder .svg-inner img {margin: 0;}
.block-lines-3 p {color: #5b87da;font-size: 17px;position: relative;font-family: $font-family-2;font-weight:700; line-height: 1.4;}
.block-lines-3 small {font-family: $font-family; display: block;color: #31333a; font-size: 13px;}


.quote-name {font-family: $font-family-2; font-weight: 700; font-size: 18px;padding-top: 12px; color: #00259a}

#projects-list { height: 100%}
#projects-list .hsContent { margin: 196px auto 0 auto; display: table-cell; vertical-align: middle; bottom: 0; max-width: 100%;  }
#projects-list h1.title {position: fixed;width: 100%;bottom: -60px;left: 0;line-height: 320px;}

.project-list-nav { padding-bottom: 10px;}
.project-list-nav ul{ list-style: none; padding: 0;margin: 0;}
.project-list-nav ul li{; display: inline-block; padding-right: 20px;}
.project-list-nav ul li a{ text-decoration: none; font-family: $font-family-2;font-weight:900; color: #fff; font-size: 21px}
.project-list-nav ul li.active a { color: #5b87da}

.project-list-heading h2 {font-family: $font-family-2;font-weight:900;padding-bottom: 0; }

.project-list-cta a{text-decoration: none; color: #fff;position: relative;top: -10px;}
.project-list-cta a h1{color: #fff; line-height: 1; transition: all 0.2s ease-in-out;}
.project-list-cta a:hover h1 { color: #b67f00; }
.project-list-cta a h1 span { display: inline-block; height: 70px; line-height: 70px; margin-top: 0px; width: 45px; position: relative; bottom: -20px; left: 0; transition: all 0.2s ease-in-out; }
.project-list-cta a:hover h1 span { left: 5px; }
.project-list-cta a h1 span:before { content: ''; display: inline-block; top: 50%; }
.project-list-cta a h1 span:before, .project-list-cta a h1 span:after { display: block; position: absolute; right: auto; left: 12px; width: 4px; height: 40px; background: #fff; content: ''; transition: all 0.2s ease-in-out; }
.project-list-cta a h1 span:before { top: 0px; bottom: auto; transform: scaleX(1.3) translateX(5px) rotateZ(-36deg); }
.project-list-cta a h1 span:after { top: auto; bottom: 0px; transform: scaleX(1.3) translateX(5px) rotateZ(36deg); }
.project-list-cta a:hover h1 span:before, .project-list-cta a:hover h1 span:after { background: #b67f00; }


.blockquote-blue {position: absolute;max-width: 50%;padding: 70px;background: #00259a;color: #fff;bottom: -160px;left: 0;z-index: 8;}
.blockquote-blue em {display:block;padding-top: 20px;color: #fff;font-size: 14px;}

.contact-social a { text-decoration: none; margin-right: 10px;margin-top: 6px; display: inline-block;}
.contact-social svg#svg_twitter, .nav-bottom svg#svg_twitter {width: 19px;}
.contact-social svg#svg_linkedin, .nav-bottom svg#svg_linkedin {width: 16px;}
.contact-social svg#svg_cv {width:40px;}

.contact-social a svg path { fill:#00259a; @include transition(all .3s ease-in-out);}
.contact-social a:hover svg path {fill:#5b87da}

.contact-social p {padding-bottom: 0;margin-bottom: 0;}

.blockquote-blue-text {padding: 70px 0;color: #00259a;}
.blockquote-blue-text em {display:block;padding-top: 20px;font-size: 14px;}



.service-hover-holder { position: relative}
.service-hover-overlay {position: absolute;top:0;left: 0; right: 0; bottom: 0; background: #fff;background: rgba(255, 255, 255, 0.8);opacity: 0; @include transition(all .3s ease-in-out);}
.service-hover-inner { display: table; width: 100%; height: 100%}
.service-hover-logo { display: table-cell;vertical-align: middle;}
.service-hover-logo img{    margin: 0 auto;}

.service-hover-holder:hover .service-hover-overlay{ opacity: 1}

.row-a { display: flex; margin-left: -20px;}
.col-half { width: calc(50% - 20px); margin-left: 20px;}
.list-unstyled { list-style: none;padding: 0;margin: 0; font-size: 14px}

@media only screen and (max-width: 400px) { 
	.list-unstyled { font-size: 12px}
}


@media only screen and (min-width: 769px) { 
	.pad-right-desktop {padding-right: 60px;}
	.mw-small-desktop {max-width: 500px}

}

@media only screen and (max-width: 768px) { 
	.hero-left {position: relative;overflow: hidden;height: 50vh; min-height: 200px}
	.hero-left img {position: absolute;height: 100%; object-fit:cover;}
}






.row-2 {margin-left: -25px;}
.row-2 .col {margin-left: 25px;}
.row-2 .col.col-6 {    width: calc(50% - 25px);   flex-basis: calc(50% - 25px);}





@media only screen and (min-width: 769px) and (max-width: 1000px) { 
	.row-2 {margin-left: -25px;}
	.row-2 .col {margin-left: 25px;}
	.row-2 .col.col-6 {    width: calc(100% - 25px);   flex-basis: calc(100% - 25px);    max-width: 100%;margin-top: 10px;margin-bottom: 10px;}
}

@media only screen and (max-width: 540px) { 
	.row-2 {margin-left: -25px;}
	.row-2 .col {margin-left: 25px;}
	.row-2 .col.col-6 {    width: calc(100% - 25px);   flex-basis: calc(100% - 25px);    max-width: 100%;margin-top: 10px;margin-bottom: 10px;}
}



.lg-on {overflow: hidden!important;}


.list-2 {
    padding: 0;
    margin:10px 0;
    list-style: none;
}

.list-2 li {
    display: block;
    position: relative;
    padding-left: 22px;
    padding-bottom:30px;
    line-height: 1.5;
}

.list-2 li:before {
    content: '';
    font-weight: bold;
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #5B87DA;
    position: absolute;
    left: 0;
    top: 7px;
}



























































/* Responsive styles */
.pad-top-big { padding-top: 196px;}
.pad-top-big-2 { padding-top: 100px;}

.footer-inner h1{padding: 0 0 40px 0;}
 


@media only screen and (max-height: 830px) { 
  h1.title {font-size: 286px;line-height: 226px!important;}
  #projects-list h1.title {    line-height: 250px!important;}
  .footer-inner { padding-top: 180px}
}


@media only screen and (max-height: 770px) { 
.main-nav ul > li {font-size: 42px;}
.deck-bottom {bottom: 0;}
h1.title {padding: 0 0 20px 0;}
.navigation-inner-bottom {bottom: 35px;position: relative;top: 0;margin-bottom: 30px;    margin-top: 90px;}
.navigation-inner {position: relative;}

.main-nav {margin-left: 20px;}
.nav-bottom {margin-left: 0;}

.footer-bottom-address {position: relative;bottom: 0; margin-bottom: 30px}
footer {height: auto!important;     min-height: auto!important;}
.nav3 {height: 230px;}


}

@media only  screen and (max-width: 1110px) { 
#sync1-holder {margin-top: -175px;}
}

@media only  screen and (max-width: 1400px) { 
	.cta h1 {font-size: 48px;    top: 30px;    left: 30px;}
	.cta-block {width: 250px;    height: 180px;}
}

@media only  screen and (max-width: 1330px) { 
.secondary-nav-holder, .footer-holder {    left: -80px; display: none}
}


@media only screen and (max-width: 1300px) { 
.navigation-inner-bottom .offset-1 {padding-left: 40px!important;}
.desktop-indent-left {position: relative;left: 0;}
.button.big {font-size: 16px;padding: 20px 28px;}
}

@media only screen and (max-width: 1160px) { 
.open .navigation-inner-bottom {width: 90%;float: right;right: 0;left: auto;}
.navigation-inner-bottom .offset-1 {padding-left: 0px!important;margin-left: 0%!important;}

}

@media only screen and (max-width: 1170px) { 
h1.title {font-size: 286px;line-height: 216px!important;padding: 0 0 40px 0;}
#projects-list h1.title {    line-height: 240px!important;}
}

@media only screen and (max-width: 1023px) { 
	.container-pad-70  { padding-top: 50px; padding-bottom: 50px;}


  h1.title {font-size: 206px;line-height: 136px!important;padding: 0 0 40px 0;}
  #projects-list h1.title {    line-height: 160px!important;}
  .nav-bottom { display: none}
  .pad-top-big-2 {padding-top: 60px;}

  .block-lines ul li {width: 50%;}
  .block-lines-2 ul li {width: 33.33333%;}


  .block-lines-3 ul li{width:calc(33.33333% - 50px);}

  .cta-block {width: 220px;height: 180px;}
  .cta h1 {padding: 0 0 55px 0;font-size: 40px;margin-left: 0px;}
  .cta.cta-full h1 {padding: 0 0 41px 0;}

  .cta-block {height: 120px;}
  .cta h1 { top:20px}
  .cta-exterior h1 { bottom:20px}

  .cta h1 span {height: 50px;line-height: 50px;bottom: -17px;}
  .cta h1 span:before, .cta h1 span:after { height: 29px}


  .cta-interior h1 {left: 90px;top: 13px;}
  .cta-exterior h1 {right: 90px;padding-bottom: 26px; bottom:8px; top:auto}

  .cta-exterior .cta-layer, .cta-interior .cta-layer { display: none} 
  .cta-content {display: none}

  .cta-exterior .cta-block, .cta-interior .cta-block {height: 100px;width: 160px;}

  .project-list-cta a h1 {font-size: 40px;}
  .project-list-cta a h1 span {height: 50px;line-height: 50px;bottom: -15px;}
  .project-list-cta a h1 span:before, .project-list-cta a h1 span:after {height: 29px;}

  .e-small { }
}

@media only screen and (max-width: 960px) { 
.nav a {font-size: 22px;}
.nav li li a {font-size: 19px;}
}





@media only screen and (min-width: 769px) { 
  .float-right-desktop { float: right;}
  .desktop-pad-right { padding-right: 40px}
  .desktop-pad-left {padding-left: 70px}
  .desktop-narrow{max-width: 440px;}
   
  .desktop-text-right { text-align: right;}

  .head-7 {min-height: 70px; display: flex;flex-direction:column;justify-content:flex-end}
}

@media only screen and (max-width: 768px) { 
  .center-mobile-pad { margin: 0 auto 60px auto}
  .mobile-center { margin: 0 auto}
  .text-center-mobile { text-align: center;}
  .mar-bottom-60-mobile { margin-bottom: 60px}
  .mar-bottom-60-desktop { margin-bottom: 390px}

  .container-pad { padding-top: 30px; padding-bottom: 30px;}
  .container-pad-top { padding-top: 30px;}
  .container-pad-bottom{padding-bottom: 30px;}
  .container-pad-top-70 {padding-top: 10px;}
  .container-pad-top-40 {padding-top: 30px;}

  .item-pad-right { padding-right: 30px; padding-top: 30px; max-width: 100%}
  .item-pad-left {padding-left: 30px; padding-top: 30px; padding-bottom: 30px; max-width: 100%}
  .container .item-pad-left {padding-left: 0; padding-right: 0; }

  .item-pad-left2 { max-width: 100%; width: 100%; padding-left: 30px; padding-top: 30px; padding-right: 30px; padding-bottom: 0px; }


 
  .pad-top-big {padding-top: 30px;}
  .pad-top-big.pad-top-big-3 {padding-top: 126px;padding-left: 0;padding-right: 0;}

  .col-sm-6 {-ms-flex-preferred-size: 50%!important;flex-basis: 50%!important;max-width: 50%!important;width: 50%!important;}


  .open .navigation-inner-bottom {width: 100%;padding-bottom: 30px;}
  .navigation-inner-bottom { bottom: 0px; position: relative;margin-top: 60px;}

  .navigation-inner {top: 45px;position: relative;}
  .logo-container {top: 45px;}

  

  #nav-icon, #nav-icon.open {left: 30px;margin-left: 0;top: 33px;}
  .navigation.open {width: 100%;}

  #nav-icon { width: 50px}
  .icon-E, .icon-E:before, .icon-E:after {width: 50px;height: 3px;}
  .icon-E:before {top:-20px}
  .icon-E:after { top: 20px}

  .icon-X:before, .icon-X:after {width: 50px;height: 3px;}

  .main-nav {margin-left: 0px;float: left !important;}

  .main-nav li ul {padding-left: 10px;}
  .main-nav ul > li {font-size: 28px;}
  .main-nav ul > li:hover li a:hover {padding-left: 22px;}

  .hsContent { margin: 136px auto 0 auto; }

  .footer-inner {padding-top: 136px;}

  h1.title {font-size: 146px;line-height: 76px !important;}
  #projects-list h1.title {    line-height: 120px!important;}
  h1, .h1 {font-size: 50px;line-height: 1;padding: 0 0 30px 0;}

  .footer-holder, .secondary-nav-holder { display: none}

  h2, .h2 { padding-bottom: 20px;}

  blockquote.blockquote-white {padding: 20px 0 0 0;margin-top: 0; margin-left: 0;}

  .block-lines {margin-top: 0px;margin-bottom: 30px;}

  #brodosplit {width: 100%;max-width: 260px;}
  #brodo-head { margin-bottom: 20px}
  .h1-small {font-size: 63px;}


  #our-team {margin-top: 120px;}
  .blue-indent {padding: 30px;}
  #sync1-holder {margin-top: 120px;}
  #sync2 .item {font-size: 25px;text-align: center;}
  .owl-nav-holder {top: -130px;}
  .team-quote {margin: 30px;}
  .team-indent {margin-top: 0;padding-top: 0px;}
  #our-team .owl-controls { display: none!important}

  .logo-chelsea-group {padding-top: 0;}
  .logo-chelsea-group img {max-width: 150px;margin: -30px auto 30px auto;}


  .block-lines-2 ul li {width: 50%;}


	.block-lines-3 ul{width:calc(100% + 20px); margin-left: -20px;margin-right: -20px; justify-content:flex-start}
	.block-lines-3 ul li{width:calc(50% - 20px);margin-left: 20px;}



  .blockquote-blue {position: static;max-width: 100%;padding: 30px;}

  .cta.cta-full .cta-block {top: auto;left: 0;bottom: 0;}
  .cta.cta-full h1 {left: 0;margin-left: 30px;}

  #projects-list .hsContent {margin: 136px auto 0 auto;}

}


@media only screen and (max-width: 600px) { 
h1.title {font-size: 106px;line-height: 36px !important;}
#projects-list h1.title {    line-height: 60px!important;}
.down-arrow:before, .down-arrow:after {height: 30px;}
.down-arrow:before {left: 23px;}
.down-arrow:after {left: 44px;}

.main-nav ul {min-width: auto; width: 100%}
.nav ul {min-width: auto;width: 100%}
.nav3 li ul {position: relative;}
.nav ul > li {float: none;}
.footer-bottom-address {position: relative;top: auto;bottom: auto;margin: 30px 0;}
footer.skrollr-deck {min-height: 100%;height: initial;}

  h1, .h1 {font-size: 36px;line-height: 1;padding: 0 0 30px 0;}

  .cta-block {width: 80%;height: 115px;}
  .cta h1 {top: 20px;left: 40px;}
  .cta-exterior h1 {top: auto;bottom: 0px;left: auto;right: 30px;}

  .cta h1 span { height: 44px;line-height: 44px;bottom: -10px; opacity: 1; }
  .cta h1 span:before, .cta h1 span:after { height: 25px;background-color: #b67f00}

  .block-lines ul li {width: 100%;}
  .block-lines ul li, .block-lines ul li svg, .block-lines ul li h1, .block-lines ul li p { text-align: center;}
  .button.big {font-size: 18px;padding: 14px 18px;font-size: 13px;}

  .block-lines-2 ul li {/*width: 100%;*/}
  .block-lines-2 p {font-size: 14px}
  .block-lines-2 small {font-size: 11px;}

  .hero-secondary.hero-dark p {color: rgba(255, 255, 255, 0.9);}

  .nav3 {height: auto;}

  .cta-gallery .cta-block {width: 280px;height: 120px;}
  .cta.cta-gallery h1 small { font-size: 14px}
  .cta h1 em {font-size: 13px;}

}

@media only screen and (max-width: 500px) { 
h1.title {font-size: 86px;line-height: 36px !important;}
#projects-list h1.title {    line-height: 60px!important;}
}


@media only screen and (max-height: 400px) { 
 #projects-list .hsContent {margin: 100px auto 0 auto;}
}







@media only screen and (max-width: 1100px) { 
.col-md-4 {-ms-flex-preferred-size: 33.33333%!important;flex-basis: 33.33333%!important;max-width: 33.33333%!important;width: 33.33333%!important;}

}



/* Prevents Mobile Safari from bumping up font sizes in landscape */
@media only screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}







