// row
@mixin grid-row {

	@include flex;
	@include flex-wrap;

    margin-left: -$grid-gap;
}

// column
@mixin grid-column($num) {

	$width: (100% / $grid-columns) * $num;

    flex-basis: $width;
    max-width: $width;
    width: $width;
}

// column offset
@mixin grid-column-offset($num) {

	$width: (100% / $grid-columns) * $num;

	margin-left: $width;
}

// grid media
@mixin grid-media-columns($num) {

	-webkit-column-count: $num;
	-moz-column-count: $num;
	column-count: $num;

	-webkit-column-gap: $grid-gutter;
	-moz-column-gap: $grid-gutter;
	column-gap: $grid-gutter;

	& > div {
		display: inline-block;
		width: 100%;
	}

	@media (max-width: $breakpoint-small) {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
	}
}